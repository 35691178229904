import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ButtonImg from "../../../assets/Dashboard/new-images/btn-image.svg";

const ScanBtn = ({ name, isQuickScan, path }) => {
    return (
        <Link to={{
            pathname: path,
            state: { isQuickScan }
        }}>
            <Button className="scan-btn d-flex justify-content-end px-0">
            <span className={`font-size-12 ${isQuickScan ? 'order-1' : 'order-2'} `}>{name} <br/></span>
            <img className={`w-100px mt-2 ${isQuickScan ? 'order-1' : 'order-2'}`} src={ButtonImg} alt="button img" />
            </Button>
        </Link>
    )
}

export default ScanBtn;