import * as types from "../Action";

const initState = {
  responseImages: [
    {
      title: "front Teeth",
    },
    {
      title: "Upper Jaw",
    },
    {
      title: "Lower Jaw",
    },
    {
      title: "Left Side",
    },
    {
      title: "Right Side",
    },
  ],
  imageCount: 0,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  switch (action.type) {
    case types.USER_REPORT_SUCCESS:
      let newArray = state.responseImages;
      newArray[action.payload.imgCount - 1].image_id = action.payload.imgCount;
      newArray[action.payload.imgCount - 1].image_name = action.payload
        ? action.payload.data.imageName
        : "";
      newArray[action.payload.imgCount - 1].isReshootImage = action.payload.data.is_reshoot
      newArray[action.payload.imgCount - 1].mouthDetectionMessage = action.payload.data.mouth_detection_message
      return {
        ...state,
        responseImages: newArray,
        imageCount: action.payload.imageCount,
        isReshoot : action.payload.data.is_reshoot,
        mouthDetectionMessage : action.payload.data.mouth_detection_message
      };
    case types.USER_REPORT_RESET:
      const stateData = {...state};
      const updatedState = stateData.responseImages.map((img) => (
        {
          title: img.title
        }
      ));
      stateData.responseImages = updatedState;
      stateData.imageCount = 0;
      delete stateData["isReshoot"]
      return stateData;
    case types.USER_REPORT_ERROR:
      return {};
    case types.UPDATE_IS_RESHOOT:
      const imgIndex = action.payload.imgCount
      let updatedImages = state.responseImages
      updatedImages[imgIndex].image_name = undefined
      return {
        ...state,
        responseImages: updatedImages,
        isReshoot : false
      }
    default:
      return state;
  }
}
