import * as types from "../Action";

const initState = {
  message: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  const response = action.payload;
  switch (action.type) {
    case types.USER_REPORT_UPLOAD:
      return { ...state, message: "" };
    case types.USER_REPORT_UPLOAD_SUCCESS:
      return {
        ...state,
        response,
        message: "Your Report uploaded successfully!",
      };
    case types.USER_REPORT_UPLOAD_RESET:
      return initState;
    case types.USER_REPORT_UPLOAD_ERROR:
      return { ...state, message: "Something went wrong please try again!", isError: true };
    default:
      return state;
  }
}
