import React from "react";
import Header from "../../UI/Header/Header";
import ReactPlayer from "react-player";

const Knowledge = () => {
  return (
    <div className="container-fluid">
      <Header title="Knowledge" routeRedirection="/dashboard" />
      <div className="row margin-top-70px">
        <div className="col-12 text-center my-3">
          <h4 className="mb-0 p-3">Cavity</h4>
          <ReactPlayer
            url={"https://youtu.be/PEPWF0sJClQ"}
            controls
            width="320"
            style={{ height: "400px" }}
          />
        </div>
        <div className="col-12 text-center">
          <h4 className="mb-0 p-3">Tartar</h4>
          <ReactPlayer
            url={"https://youtu.be/7aRjTjFEqUk"}
            controls
            width="320"
            style={{ height: "400px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Knowledge;
