import React, { Component } from "react";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import * as Yup from "yup";

import Swal from "sweetalert2";
import { connect } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";

import { createNewPasswordAction } from "../../../Redux/Action/AuthAction";
import InputPassword from "../../UI/Input/InputPassword";

class NewPassword extends Component {
  constructor() {
    super();
    this.state = {
      error: undefined,
      email: "",
      forgotpass_code: "",
      isLoading: false
    };
  }

  componentDidMount() {
    const data = this.props.location.state.data;
    if (data) {
      this.setState({ email: data.email, forgotpass_code: data.forgotpass_code });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.createPassword !== this.props.createPassword) {

      if (this.props.createPassword.message && !this.props.createPassword?.response) {
        this.setState({ error: this.props.createPassword.message, isLoading: false });
        Swal.fire(this.props.createPassword.message, '', 'error');
      }

      if (this.props.createPassword.message && this.props.createPassword?.response?.data) {
        this.setState({ data: this.props.createPassword.response.data, isLoading: false });
        Swal.fire(this.props.createPassword.message, '', 'success');
        this.props.history.push("/login");
      } else {
        Swal.fire(this.props.createPassword.message, '', 'error');
      }
    }
  }

  render() {
    return (
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center mb-3">
          <div className="col">
            <h4 className="text-center">Create New Password</h4>
            <Formik
              validate={(values) => {
                let errors = {};
                if (!values.password) {
                  errors.password = "Password is Required";
                }
                if (!values.confirmPassword) {
                  errors.confirmPassword = "Confirm Password is Required";
                }
                return errors;
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required("Password is Required")
                  .min(
                    8,
                    "Password is too short - should be 8 chars minimum."
                  ),
                confirmPassword: Yup.string().when("password", {
                  is: (val) => (val && val.length > 0 ? true : false),
                  then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                  ),
                }),
              })}
              initialValues={{
                email: "",
                forgotpass_code: "",
                password: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                values.email = this.state.email;
                values.forgotpass_code = this.state.forgotpass_code;
                this.props.dispatch(createNewPasswordAction(values));
                setSubmitting(false);
                this.setState({ isLoading: true });
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="input-with-icon-placeholder position-relative">
                      <InputPassword
                        id="password"
                        name="password"
                        placeholder="New Password"
                        error={errors.password}
                        value={values.password}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.password}
                      />
                    </div>
                    <div className="input-with-icon-placeholder position-relative">
                      <InputPassword
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        error={errors.confirmPassword}
                        value={values.confirmPassword}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.confirmPassword}
                      />
                    </div>

                    {this.state.email ? (
                      <div className="text-danger m-2">{this.state.error}</div>
                    ) : (
                      <div className="text-success m-2">{this.state.error}</div>
                    )}

                    <div className="text-center">
                      {this.state.isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          type="submit"
                          className="form-btn"
                        >
                          Create Password
                        </Button>
                      )}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    createPassword: state.createPassword,
  };
};

export default connect(mapStateToProps, null)(NewPassword);
