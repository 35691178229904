import React from "react";
import { Col } from "react-bootstrap";

const NoteText = (props) => {
  return (
    <Col xs={12}>
      <p className="font-size-12 mb-3">
        <span className="text-danger mr-1">
          <b>*</b>
        </span>
        If you find wrong detections, it is probable the photos included your
        face and nose or the photos are not like the demo photos. Please recheck
        and reshoot.
      </p>
      <p className="font-size-12 mb-3">
        <span className="text-danger mr-1">
          <b>*</b>
        </span>
        This is suggestive algorithm based assessment and not a diagnosis. It is not intended to treat, cure or prevent any disease. Please
        verify with a dental professional for a definitive diagnosis
      </p>
    </Col>
  );
};

export default NoteText;
