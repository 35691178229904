import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";

import { postRating } from "../../../Redux/Services/AuthService";

import RadioGroupRating from "./RatingComponent";

function RatingDialog(props) {
  const messageBox = useRef();
  const { onClose, selectedValue, open } = props;
  const [rating, setRating] = useState(0);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const selectedRrating = (rating) => {
    setRating(rating);
  }

  const submitRating = () => {
    const ratingData = {
      rating,
      message: messageBox.current.value
    };
    postRating(ratingData).then((res) => {
      handleClose();
      if (res && res.status === 200) {
        const user = JSON.parse(localStorage.getItem('user')).user;
        const updateUser = { ...user, is_rating_given: true };
        localStorage.setItem('user', JSON.stringify({ user:updateUser }));
        Swal.fire(res.data.message, '', 'success');
      }
    }).catch((err) => {
      handleClose();
      Swal.fire('Server error, Please try later', '', 'error');
    });
  }

  return (
    <Dialog onClose={handleClose} open={open} className="rating-container">
      <div className="text-right px-3">
        <i className="fas fa-times black-color" onClick={handleClose}></i>
      </div>
      <div className="px-3 mb-3">
        <h2 className="black-color font-size-18">Like using our app</h2>
        <p className="black-color">please rate your overall experience</p>
      </div>
      <RadioGroupRating value={rating} selectedValue={selectedRrating} />
      <div className="w-100 px-3">
        <textarea
          className="w-100 my-3"
          placeholder="Enter your feedback here"
          // value={message}
          ref={messageBox}
          ></textarea>
        <Button className="form-btn text-uppercase mb-4"
        onClick={submitRating}>Submit</Button>
      </div>
      
    </Dialog>
  );
}

RatingDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string,
};

export default RatingDialog;
