import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import BackArrow from "../../../assets/Dashboard/Drawer/back-arrow.svg";
import cssClasses from './Header.module.css';

const CavityTartarBackArrow = ({ routeUrl }) => {
    return (
        <Col
        xs={12}
        className={`d-flex align-items-center justify-content-center mb-3 h-auto
        ${cssClasses['header-nav-container']} position-fixed left-0 top-34px`}
      >
        <Link to={routeUrl ? routeUrl : '/dashboard'} className={`position-absolute ${cssClasses['back-btn-icon']}`}>
          <img src={BackArrow} alt="back-arrow-img" />
        </Link>
      </Col>
    )
}

export default CavityTartarBackArrow;