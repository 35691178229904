import React from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, Col, Container, Row } from "react-bootstrap";
// import Drawer from "@material-ui/core/Drawer";

import FailedTeeth from "./../../../assets/cameraModule/Failed.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BlurAndBrightnessModal = (props) => {
  const [open, setOpen] = React.useState(props.isOpenDialog);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen((prevState) => !prevState);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Container className="pt-2">
        <Row className="flex-nowrap">
          <Col xs={12} className="text-center">
            <img src={FailedTeeth} alt="FailedTeeth" />
          </Col>
        </Row>
        {props.isBlur && (
          <Row className="mx-0 my-auto flex-nowrap pt-3">
            <Col xs={1} className="text-danger">
              <i className="fa fa-times"></i>
            </Col>
            <Col xs={11} className="black-color text-left mb-1">
              <p>
                <b>Photo looks blurred</b>
              </p>
              <p>
                <b className="text-danger">Tip</b>-Blurring may result due to
                not holding the camera steady or not shooting the photo without
                focus
              </p>
            </Col>
          </Row>
        )}
        {props.isLowBrightness && (
          <Row className="mx-0 my-auto flex-nowrap pt-3">
            <Col xs={1} className="text-danger">
              <i className="fa fa-times"></i>
            </Col>
            <Col xs={11} className="black-color text-left mb-1">
              <p>
                <b>Photo is too dark</b>
              </p>
              <p>
                <b className="text-danger">Tip</b>-Dark photos maybe due to not
                using flashlight on or taking the photo from a distance.Please
                try taking photos with flashlight on and by moving the camera a
                little closer to your mouth
              </p>
            </Col>
          </Row>
        )}
      </Container>
      <DialogActions className="justify-content-center my-2">
        <Button className="px-3 font-size-18" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BlurAndBrightnessModal;
