

const imageReducer = (state = [], action) => {
    if (action.type === 'ADD_IMAGE') {
        const imgArray = [...state];
        imgArray[action.payload.imgCount - 1] = action.payload.imageUrl;
        return imgArray;
        // return [...state, action.payload];
    } else if (action.type === 'REMOVE_ALL IMAGES') {
        return [];
    } else if (action.type === "REMOVE_ONE_IMAGE") {
        const imgArray = state.filter((eachImage, i) => action.payload.imgCount !== i)
        return imgArray
    }
    return state;

};
export default imageReducer;