import { Fragment, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Col, Container, Row } from "react-bootstrap";


export default function ReloadApp(props) {
    const { reload, version } = props
    const [update, setUpdate] = useState(false)
    const [percentage, setPercentage] = useState(0)

    const reloadEntirePage = () => {
        setUpdate(true)
        const intervalId = setInterval(() => {
            setPercentage(prevCount => {
                const newCount = prevCount + 20
                if (newCount >= 100) {
                    clearInterval(intervalId)
                    setTimeout(() => {
                        localStorage.setItem("version", version)
                        window.location.reload();
                    }, 1000)
                }
                return newCount
            })

        }, 300);


    };


    const handleClose = () => {
    };

    return (
        <Fragment>
            <Dialog
                open={reload}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Container className='' style={{ width: "85vw" }}>
                    <Row className='p-3'>
                        <Col xs={12}>
                            <h5> New Update available!</h5>
                        </Col>
                    </Row>
                    <Row className='px-3'>
                        <Col>
                            {
                                !update ? <p> A new update is available. Please click on update to proceed.</p>
                                    :
                                    <>
                                        <div className="progress" style={{ height: "6px" }}>
                                            <div className="progress-bar progress-bar-warning  bg-primary " role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: `${percentage}%` }}>
                                            </div>

                                        </div>
                                        <p className="d-flex justify-content-end my-2 mb-4">{percentage}%</p>
                                    </>

                            }
                        </Col>
                    </Row>
                    <Row className='px-4'>
                        <Col className='d-flex justify-content-end'>
                            {!update && <button onClick={reloadEntirePage} className=' font-size-16 alert alert-primary text-primary' > Update</button>}
                        </Col>
                    </Row>
                </Container>
            </Dialog>
        </Fragment>
    );
}