import React from "react";
import { Link } from "react-router-dom";

const CameraCancelButton = ({ routeUrl, isQuickScan }) => {

  return (
    <Link
      to={{
        pathname: routeUrl,
        state: { isQuickScan }
      }}
      className="d-flex justify-content-end">
      <i className="fas fa-times camera-cancel-btn font-size-23 text-white d-flex justify-content-center align-items-center"></i>
    </Link>
  );
};

export default CameraCancelButton;
