import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ReportIcon from "../../../../assets/icon/report-icon.svg";
import { Button } from "@material-ui/core";


const SendReportBtnUI = ({ reportId, userId, path }) => {
    let history = useHistory();

    const redirectToBranchesPage = () => {
        if (path === '/questionnaire') {
            return history.push(path,  { userQuestionsData: [] });
        }
        return history.push(`/select-branches/${reportId}`, {
            userId
        });
    }

    return (
        <Button className="send-report-btn" onClick={redirectToBranchesPage}>
            <img src={ReportIcon} alt="report-icon" />
        </Button>
    )
}

export default SendReportBtnUI;