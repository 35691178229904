import { Button, Dialog } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import CavityTartarDetection from "./../../../assets/Dashboard/cavity-tartar-detection.svg";

const ConsultOnlineDialog = ({ isOpenDialog, message, reportId }) => {
    const [isDialogOpen, setDialogOPen] = useState(isOpenDialog);
    let history = useHistory();
    const user = JSON.parse(localStorage.getItem('user'))?.user;

    useEffect(() => {
    }, []);

    const handleClose = () => {
        setDialogOPen((prevState) => !prevState);
    };

    const redirectToBranchesPage = () => {
        history.push(`/select-branches/${reportId}`, { userId: user._id })
      }

    return (
        <Fragment>
            <Dialog onClose={handleClose} open={isDialogOpen}>
                <Col>
                    <Row className="p-3">
                        <Col>
                            <div className="d-flex justify-content-end">
                                <i className="fas fa-times detection-color font-size-23 d-flex align-items-center justify-content-center camera-cancel-btn align-content-end"
                                    onClick={handleClose}></i>
                            </div>
                        </Col>
                        <Col xs={12} className="my-3 text-center">
                            <h5 className="font-size-16 detection-color">{message}</h5>
                        </Col>
                        <Col xs={12} className="text-center">
                            <img src={CavityTartarDetection} alt="Logo" />
                            <div className="mt-3 mb-2">
                                {/* <a  href="https://teledentists.vsee.me/u/toothlens" target="_blank" className="primary-color">
                                    Need to talk to a dentist?
                                </a> */}
                                {/* <p className="p-3 my-2 black-color">
                                    Click link below to start a video call with a TeleDentist.
                                </p> */}
                                <div>
                                    <Button
                                        type="submit"
                                        className="form-btn text-capitalize font-size-16"
                                        onClick={redirectToBranchesPage}
                                    >
                                        Connect with our dentist
                                    </Button>
                                </div>
                            </div>
                            <p className="p-3 my-2 black-color">
                                Consult with a dentist about your scan results and get answers to your oral health questions now.
                            </p>
                        </Col>
                        <Col className="text-right mt-2">

                        </Col>
                    </Row>
                </Col>
            </Dialog>
        </Fragment>
    );
};

export default ConsultOnlineDialog;
