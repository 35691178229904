import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";

const InsightsCavityTartarCardSlider = (props) => {
  const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    cssEase: "linear",
  };
  return (
    <Slider {...settings}>
      {props.reportScoreImg.map((item, index) => {
        return (
          props.reportScores[item][props.isTartar ? 0 : 1] > 0 && (
            <div key={item} className="cavity-slider">
              <Card className="cavity-card">
                <Card.Img
                  variant="top"
                  src={props.imgUrl + item.split("-").join(".")}
                  alt="report images"
                />
              </Card>

              {props.isTartar ? (
                <div className="px-3 black-color font-size-17 mt-5">
                  <div className="row mb-3">
                    <div className="col-2 text-center pr-0">
                      <i className="far fa-circle primary-color font-size-16 circle-icon" />
                    </div>
                    <div className="col-10 pl-0 pr-5">
                      <p>
                        The tooth scan has tagged {props.reportScores[item][0]}{" "}
                        locations for tartar or potential tartar
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="px-3 black-color font-size-17 mt-5">
                  <div className="row mb-3">
                    <div className="col-2 text-center pr-0">
                      <i className="far fa-circle primary-color font-size-16 circle-icon" />
                    </div>
                    <div className="col-10 pl-0 pr-5">
                      <p>
                        The tooth scan has tagged {props.reportScores[item][1]}{" "}
                        locations for cavities or potential cavities
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        );
      })}
    </Slider>
  );
};

export default InsightsCavityTartarCardSlider;
