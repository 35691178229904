import React, { useState, useEffect } from "react";
import "date-fns";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ImageUploading from "react-images-uploading";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from "moment";

import { profileAction } from "../../../../Redux/Action/AuthAction";
import userProfile from "../../../../assets/Dashboard/Mask-Group.svg";
import Header from "../../../UI/Header/Header";
import "./ProfileDetails.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
    },
    container: {
      height: "100vh",
    },
    button: {
      margin: "20px",
    },
  },
}));
const ProfileDetails = (props) => {
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("user"))?.user
  );
  const classes = useStyles();
  const [images, setImages] = React.useState([]);
  const [error, setError] = useState("");
  const [isdatePickerOpen, setIsdatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const [profileDetailsData, setProfileDetailsData] = useState(userDetails);
  const heading = props.location.state?.title;
  let history = useHistory();
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [open, setOpen] = useState(false);

  const setPickerStatus = (status) => {
    setIsdatePickerOpen(status);
  };
  const [form, setform] = useState({});
  useEffect(() => {
    if (!userDetails) {
      history.push("/dashboard");
    }
    setError(props.profileResponse.message);
    if (
      props.profileResponse.message === "Your profile updated successfully!"
    ) {
      const updatedUserData = props.profileResponse.response.data.data;
      const userData = { ...userDetails, ...updatedUserData };
      localStorage.setItem("user", JSON.stringify({ user: userData }));
      Swal.fire("User data updated successfully!", "", "success");
      props.dispatch({ type: "USER_PROFILE_CLEAR" });
      history.push("/dashboard");
    }
    if (props.userData?.response?.data?.data?.user) {
      setProfileDetailsData(props.userData.response.data.data.user);
    } else {
      setProfileDetailsData(userDetails);
    }
  }, [props.profileResponse, props.userData?.response?.data?.data?.user]);

  return (
    <Container className="faq-bg">
      <Header
        title={heading ? heading : "Profile details"}
        routeRedirection="/dashboard"
      />
      <Row className="margin-top-70px">
        <Formik
          validate={(values) => {
            let errors = {};
            if (!values.first_name) {
              errors.first_name = "First Name is Required";
            }
            if (!values.last_name) {
              errors.last_name = "Last Name is Required";
            }
            const phoneRegExp =
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

            if (!values.phone_number) {
              errors.phone_number = "Phone number is Required";
            } else if (!phoneRegExp.test(values.phone_number)) {
              errors.phone_number = "Invalid Phone number Number";
            }
            if (!values.dob) {
              errors.dob = "Date of Birth is Required";
            }
            if (!values.gender) {
              errors.gender = "Gender is Required";
            }
            if (!values.city) {
              errors.city = "City is Required"
            }
            if (!values.state) {
              errors.state = "State is Required"
            }
            if (!values.country) {
              errors.country = "Country is Required"
            }
            if (!values.zip_postal_code) {
              errors.zip_postal_code = "Zip/postal code is Required"
            }
      
            return errors;
          }}
          validationSchema={Yup.object().shape({
            dob: Yup.string()
              .required("Please enter your dob")
              .test("DOB", "User must be atleast 3 years old", (value) => {
                return moment().diff(moment(value), "years") >= 3;
              }),
          })}
          initialValues={{
            first_name: profileDetailsData ? profileDetailsData.first_name : "",
            last_name: profileDetailsData ? profileDetailsData.last_name : "",
            dob: profileDetailsData ? profileDetailsData.dob : null,
            gender: profileDetailsData ? profileDetailsData.gender : "",
            city: profileDetailsData ? profileDetailsData.city : "",
            country: profileDetailsData ? profileDetailsData.country : "",
            state: profileDetailsData ? profileDetailsData.state : "",
            phone_number: profileDetailsData
              ? profileDetailsData.phone_number
              : "",
              zip_postal_code: profileDetailsData
              ? profileDetailsData.zip_postal_code
              : "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            props.dispatch(profileAction(values));
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            const dob = new Date(values.dob);
            let diff_ms = Date.now() - dob.getTime();
            let age_dt = new Date(diff_ms);
            const age = Math.abs(age_dt.getUTCFullYear() - 1970);

            return (
              <form
                onSubmit={handleSubmit}
                className={`${classes.root} mt-3 w-100`}
              >
                {/* <Col className="profile-picture-center">
                  <ImageUploading
                    value={images}
                    name="profile"
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    className="mx-auto"
                  >
                    {({ imageList, onImageUpload, isDragging, dragProps }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          className="image-upload-btn"
                          {...dragProps}
                        >
                          <i className="fas fa-camera"></i>
                        </button>
                        <div className="image-border my-5 mx-auto">
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image.data_url} alt="logo" />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </Col> */}
                <Col xs={12}>
                <div className="d-flex justify-content-end"><span style={{color:"red"}}>*</span>Required fields</div>
                </Col>

                <Col xs={12} className="profile-input">
                  <FormLabel
                    component="legend"
                    className="ml-3 pt-3 font-weight-500 text-dark "
                  >
                    First Name<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <input
                    id="standard-basic"
                    className="form-input mb-3 grey-bg-color"
                    placeholder="First Name"
                    defaultValue={values.first_name}
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.first_name && touched.first_name && (
                    <div className="text-danger mb-2 ml-2">
                      {errors.first_name}
                    </div>
                  )}
                  <FormLabel
                    component="legend"
                    className="ml-3 pt-3 font-weight-500 text-dark "
                  >
                    Last Name<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <input
                    id="standard-basic"
                    className="form-input mb-3 grey-bg-color"
                    placeholder="Last Name"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.last_name && touched.last_name && (
                    <div className="text-danger mb-2 ml-2">
                      {errors.last_name}
                    </div>
                  )}
                  {/* <FormLabel
                    component="legend"
                    className="ml-3 pt-3 font-weight-500 text-dark "
                  >
                    Email Address
                  </FormLabel>
                  <input
                    id="standard-basic"
                    className="form-input mb-3 grey-bg-color"
                    placeholder="Email Address"
                    name="email"
                    defaultValue={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  /> */}
                                  </Col>

                  <Col xs={12} className="date-picker">
                    <FormLabel
                      component="legend"
                      className="ml-3 pt-3 font-weight-500 text-dark "
                    >
                      Date of Birth<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          id="dob"
                          format="MM/dd/yyyy"
                          name="dob"
                          className="form-input mb-3 grey-bg-color"
                          defaultValue={values.dob}
                          value={values.dob}
                          onChange={(value) =>
                            props.setFieldValue("dob", value)
                          }
                          onClick={() => setPickerStatus(true)}
                          onClose={() => setPickerStatus(false)}
                          open={isdatePickerOpen}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                      {errors.dob && touched.dob && (
                        <div className="text-danger mb-2 ml-2">
                          {errors.dob}
                        </div>
                      )}
                    </MuiPickersUtilsProvider>
                  </Col>
                  <Col xs={12} className="profile-input">
                  <div disabled={true}>
                    <FormLabel
                      component="legend"
                      className="ml-3 pt-3 font-weight-500 text-dark "
                      hidden={profileDetailsData.dob ? false : true}
                    >
                      Age
                    </FormLabel>
                    <input
                      id="standard-basic"
                      className="form-input mb-3 grey-bg-color"
                      placeholder="Age"
                      value={age}
                      name="age"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      hidden={profileDetailsData.dob ? false : true}
                    />
                  </div>
</Col>


                <Col xs={12}>
                  <FormControl component="fieldset" className="w-100">
                    <FormLabel
                      component="legend"
                      className="ml-3 pt-3 font-weight-500 text-dark "
                    >
                      Gender<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender"
                      className="justify-content-around"
                      value={values.gender}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio color="primary" />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio color="primary" />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio color="primary" />}
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>
                  {errors.gender && touched.gender && (
                    <div className="text-danger mb-2 ml-2">{errors.gender}</div>
                  )}
                </Col>
                <Col xs={12} className="profile-input">
                <FormLabel
                  component="legend"
                  className="ml-3 pt-3 font-weight-500 text-dark "
                >
                  Cell/Mobile Number<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <input
                  className="form-input my-3 grey-bg-color"
                  id="standard-basic"
                  placeholder="Phone Number"
                  name="phone_number"
                  defaultValue={values.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone_number && touched.phone_number && (
                  <div className="text-danger mb-2 ml-2">
                    {errors.phone_number}
                  </div>
                )}
</Col>
               
                {/* <Col xs={12} className="profile-input">
                  <FormLabel
                    component="legend"
                    className="ml-3 pt-3 font-weight-500 text-dark "
                  >
                    Address
                  </FormLabel>
                  <input
                    id="standard-basic"
                    className="form-input mb-3 grey-bg-color"
                    placeholder="Address"
                  />
                </Col> */}
  <Col xs={12} className="profile-input">

                <FormLabel
                  component="legend"
                  className="ml-3 pt-3 font-weight-500 text-dark "
                >
                  City<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <input
                  id="standard-basic"
                  className="form-input my-3 grey-bg-color"
                  placeholder="City"
                  name="city"
                  defaultValue={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.city && touched.city && (
                  <div className="text-danger mb-2 ml-2">
                    {errors.city}
                  </div>
                )}
               
                  </Col>
                  <Col xs={12} className="profile-input">

                <FormLabel
                  component="legend"
                  className="ml-3 pt-3 font-weight-500 text-dark "
                >
                  State<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="State"
                  name="state"
                  defaultValue={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.state && touched.state && (
                  <div className="text-danger mb-2 ml-2">
                  {errors.state}
                </div>
                )}
             
                </Col>
                                <Col xs={12} className="profile-input">
                                <FormLabel
                    component="legend"
                    className="ml-3 pt-3 font-weight-500 text-dark "
                  >
                    Zip/Postal Code<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <input
                    className="form-input my-3 grey-bg-color"
                    id="standard-basic"
                    placeholder="Zip/postal code"
                    name="zip_postal_code"
                    defaultValue={values.zip_postal_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.zip_postal_code && touched.zip_postal_code && (
                  <div className="text-danger mb-2 ml-2">
                    {errors.zip_postal_code}
                  </div>
                )}
                
</Col>
               
                <Col xs={12} className="profile-input">
                  <FormLabel
                    component="legend"
                    className="ml-3 pt-3 font-weight-500 text-dark "
                  >
                    Country<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <input
                    id="standard-basic"
                    className="form-input my-3 grey-bg-color"
                    placeholder="Country"
                    name="country"
                    defaultValue={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.country && touched.country && (
                    <div className="text-danger mb-2 ml-2">
                      {errors.country}
                    </div>
                  )}
                
                </Col>

                {error ? (
                  <div className="text-success m-2 pl-3">{error}</div>
                ) : (
                  <div className="text-danger m-2 pl-3">{error}</div>
                )}
                <Col className={classes.root}>
                  <Button
                    type="submit"
                    // disabled={isSubmitting}
                    className="form-btn mb-4"
                  >
                    Save and Continue
                  </Button>
                </Col>
              </form>
            );
          }}
        </Formik>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.login,
    profileResponse: state.userProfile,
  };
};

export default connect(mapStateToProps, null)(ProfileDetails);
