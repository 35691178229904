import React from "react";
import {Link} from 'react-router-dom';
import './Subscription.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
    const settings = {
        infinite: false,
        speed: 700,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1
                }
            },],
        cssEase: 'linear'
    };
    return (
        <div className='overflow'>
            <div className='d-flex '>
                <Link to='/dashboard'>
                    <i className="fas fa-chevron-left dashboard-btn ml-2"></i>
                </Link>
                <div className='subscription-header mb-5 mx-auto'>
                    <h6 className='mb-3 mt-3'>Subscription</h6>
                    <h4>TOOTHLENS CHECK</h4>
                </div>
            </div>


            <Slider {...settings}>
                <div className='subscription-card-starter mx-auto'>
                    <h5 className='header'>Starter Plan</h5>
                    <div className='plans'>
                        <p> <i className="fas fa-check-circle" /> Unlimited virtual checks</p>
                        <p> <i className="fas fa-check-circle" /> Dental hygiene score</p>
                        <p> <i className="fas fa-check-circle" /> Cavity count</p>
                        <p> <i className="fas fa-check-circle" /> Tartar/Plaque Count</p>
                    </div>
                    <hr />
                    <p className='footer'>Free Forever</p>
                </div>
                <div className='subscription-card-basic'>
                    <h5 className='header'>Basic Plan <span>$/month</span></h5>
                    <div className='plans'>
                        <p> <i className="fas fa-check-circle" /> Unlimited virtual checks</p>
                        <p> <i className="fas fa-check-circle" /> Dental hygiene score</p>
                        <p> <i className="fas fa-check-circle" /> Cavity count</p>
                        <p> <i className="fas fa-check-circle" /> Tartar/Plaque Count</p>
                        <p> <i className="fas fa-check-circle" /> Tartar/ Plaque location finder</p>
                        <p> <i className="fas fa-check-circle" /> Cavity care recommendation</p>
                        <p> <i className="fas fa-check-circle" /> Tartar plaque care recommendation</p>
                    </div>
                    <hr />
                    <p className='footer'>Subscribe</p>
                </div>
            </Slider>
        </div>
    );
}






// import React from 'react';
// import './Subscription.css';
// import { Container, Row, Col } from 'react-bootstrap';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

// class Subscription extends React.Component {

//     render(){
//         const subSettings = {
//             speed: 300,
//             slidesToShow: 1
//         };
//         return (
//             <div>
//                 <Container>
//                     <Row>
//                         <Col>
                            // <div className='subscription-header'>
                            //     <h6>Subscription</h6>
                            //     <h4>TOOTHLENS CHECK</h4>
                            // </div>
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Slider {...subSettings}>
                            // <div className='subscription-card-starter'>
                            //     <h5 className='header'>Starter Plan</h5>
                            //     <div className='plans'>
                            //         <p> <i className="fas fa-check-circle" /> Unlimited virtual checks</p>
                            //         <p> <i className="fas fa-check-circle" /> Dental hygiene score</p>
                            //         <p> <i className="fas fa-check-circle" /> Cavity count</p>
                            //         <p> <i className="fas fa-check-circle" /> Tartar/Plaque Count</p>
                            //     </div>
                            //     <hr />
                            //     <p className='footer'>Free Forever</p>
                            // </div>
                            // <div className='subscription-card-basic'>
                            //     <h5 className='header'>Basic Plan <span>$/month</span></h5>
                            //     <div className='plans'>
                            //         <p> <i className="fas fa-check-circle" /> Unlimited virtual checks</p>
                            //         <p> <i className="fas fa-check-circle" /> Dental hygiene score</p>
                            //         <p> <i className="fas fa-check-circle" /> Cavity count</p>
                            //         <p> <i className="fas fa-check-circle" /> Tartar/Plaque Count</p>
                            //         <p> <i className="fas fa-check-circle" /> Tartar/ Plaque location finder</p>
                            //         <p> <i className="fas fa-check-circle" /> Cavity care recommendation</p>
                            //         <p> <i className="fas fa-check-circle" /> Tartar plaque care recommendation</p>
                            //     </div>
                            //     <hr />
                            //     <p className='footer'>Subscribe</p>
                            // </div>
//                         </Slider>
//                     </Row>
//                 </Container>
//             </div>
//         )
//     }

// }
// export default Subscription