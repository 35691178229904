// import React from 'react'
import React, { useState, useEffect, useRef } from "react";
import "date-fns";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import Swal from "sweetalert2";
import ImagePreview from "./ImagePreview";
import {
  getLatestInsurance,
  imgtodb,
  insurancepic,
} from "../../../../Redux/Services/AuthService";
import Header from "../../../UI/Header/Header";
import "./InsuranceDetails.css";
import CavityTartarSkeleton from "../../../UI/skeleton/cavityTartarSkeleton";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
    },
    container: {
      height: "100vh",
    },
    button: {
      margin: "20px",
    },
  },
}));

const InsuranceDetials = () => {
  const classes = useStyles();
  const [state, setstate] = useState(false);
  const [isdatePickerOpen, setIsdatePickerOpen] = useState(false);
  const [profileDetailsData, setProfileDetailsData] = useState();
  const [loading, setloading] = useState(false);
  let history = useHistory();

  const setPickerStatus = (status) => {
    setIsdatePickerOpen(status);
  };

  useEffect(() => {
    getLatestInsurance()
      .then((res) => {
        if (res.status === 200) {
          setProfileDetailsData(res.data.data[0]);
          setstate(true);
        }
      })
      .catch((err) => {
        return(err);
      });
  }, []);

  if (!state) {
    return (
      <div>
        <Header title={"Insurance details"} routeRedirection="/dashboard" />
        <CavityTartarSkeleton />
      </div>
    );
  }
  if (state) {
    return (
      <Container>
        <Header title={"Insurance details"} routeRedirection="/dashboard" />
        <Row className="margin-top-70px insurance-details">
          {
            <Formik
              validate={(values) => {
                let errors = {};
                if (!values.plan_name) {
                  errors.plan_name = "Plan Name is required";
                }
                if (!values.insurance_name) {
                  errors.insurance_name = "Insurance Name is required";
                }
                if (!values.member_id) {
                  errors.member_id = "Member ID is required";
                }
                if (!values.insurance_date) {
                  errors.insurance_date = "Insurance Date is required";
                }
                if (!values.file) {
                  errors.file = "Insurance card is required";
                }
                return errors;
              }}
              initialValues={{
                insurance_name: profileDetailsData
                  ? profileDetailsData.insurance_name
                  : "",
                plan_name: profileDetailsData
                  ? profileDetailsData.plan_name
                  : "",
                member_id: profileDetailsData
                  ? profileDetailsData.member_id
                  : "",
                insurance_date: profileDetailsData
                  ? profileDetailsData.insurance_date
                  : null,
                file: profileDetailsData ? profileDetailsData.file : null,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setloading(true);
                const reader = new FileReader();
                reader.readAsDataURL(values.file);
                reader.onload = async () => {
                  await insurancepic(reader.result)
                    .then((response) => {
                      if (response.data) {
                        const data = {
                          ...values,
                          BaseUrl: response.data.BaseUrl,
                          fullUrl: response.data.fullUrl,
                          imageName: response.data.imageName,
                        };
                        imgtodb(data)
                          .then((res) => {
                            if (res) {
                              setloading(false);
                              Swal.fire(
                                "Insurance details updated successfully!",
                                "",
                                "success"
                              );
                              history.push("/dashboard");
                            }
                          })
                          .catch((err) => {
                            setloading(false);
                            return err;
                          });
                      }
                    })
                    .catch((error) => {
                      setloading(false);
                      return error;
                    });
                };
                setSubmitting(false);
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  handleSubmit,
                } = props;

                return (
                  <form
                    onSubmit={handleSubmit}
                    className={`${classes.root} mt-3 w-100`}
                  >
                    <Col xs={12} className="profile-input">
                      <FormLabel
                        component="legend"
                        className="ml-3  font-weight-500 text-dark "
                      >
                        INSURANCE INFO (below fields are not mandatory and you
                        can fill later)
                      </FormLabel>

                      <FormLabel
                        component="legend"
                        className="ml-3 pt-3 font-weight-500 text-dark "
                      >
                        Insurance Name
                      </FormLabel>
                      <input
                        className="form-input grey-bg-color"
                        id="standard-basic"
                        placeholder="Insurance name here"
                        name="insurance_name"
                        value={values.insurance_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.insurance_name && touched.insurance_name && (
                        <div className="text-danger mb-2 ml-2">
                          {errors.insurance_name}
                        </div>
                      )}
                      <FormLabel
                        component="legend"
                        className="ml-3 pt-3 font-weight-500 text-dark "
                      >
                        Plan Name
                      </FormLabel>
                      <input
                        className="form-input grey-bg-color"
                        id="standard-basic"
                        placeholder="Plan name here"
                        name="plan_name"
                        value={values.plan_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.plan_name && touched.plan_name && (
                        <div className="text-danger mb-2 ml-2">
                          {errors.plan_name}
                        </div>
                      )}
                      <FormLabel
                        component="legend"
                        className="ml-3 pt-3 font-weight-500 text-dark "
                      >
                        Member ID
                      </FormLabel>
                      <input
                        className="form-input  grey-bg-color"
                        id="standard-basic"
                        placeholder="Member ID"
                        name="member_id"
                        value={values.member_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.member_id && touched.member_id && (
                        <div className="text-danger mb-2 ml-2">
                          {errors.member_id}
                        </div>
                      )}
                      <div className="date-picker">
                        <FormLabel
                          component="legend"
                          className="ml-3 pt-3 font-weight-500 text-dark "
                        >
                          Insurance Date
                        </FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                              margin="normal"
                              id="dob"
                              format="MM/dd/yyyy"
                              name="insurance_date"
                              placeholder="Insurance date"
                              className="form-input mb-3 grey-bg-color"
                              value={values.insurance_date}
                              onChange={(value) =>
                                props.setFieldValue("insurance_date", value)
                              }
                              onClick={() => setPickerStatus(true)}
                              onClose={() => setPickerStatus(false)}
                              open={isdatePickerOpen}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                          {errors.insurance_date && touched.insurance_date && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.insurance_date}
                            </div>
                          )}
                        </MuiPickersUtilsProvider>
                      </div>

                      <FormLabel
                        component="legend"
                        className="ml-3 pt-3 font-weight-500 text-dark "
                      >
                        Insurance Card picture
                      </FormLabel>
                      {values.file && <ImagePreview file={values.file} />}
                      <div className="custom-file ">
                        <input
                          className="my-3 custom-file-input"
                          placeholder="Upload your Card"
                          type="file"
                          value={""}
                          onChange={(event) => {
                            setFieldValue("file", event.target.files[0]);
                          }}
                          onBlur={handleBlur}
                        />
                        <label className="custom-file-label" for="customFile">
                          Choose file
                        </label>
                      </div>
                      {errors.file && touched.file && (
                        <div className="text-danger mb-2 ml-2">
                          {errors.file}
                        </div>
                      )}
                      {profileDetailsData ? (
                        <Button
                          variant="contained"
                          className={`${classes.drawerHeader} mb-4 mt-3`}
                          type="button"
                          hidden={profileDetailsData ? false : true}
                          href={profileDetailsData.fullUrl}
                          color="transparent"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className="fa fa-download pr-2"
                            aria-hidden="true"
                          ></i>
                          Download your Insurance card{" "}
                        </Button>
                      ) : null}
                    </Col>
                    {loading ? (
                      <Col xs={12}>
                        <div className="d-flex w-5 justify-content-center my-4">
                          <CircularProgress />
                        </div>
                      </Col>
                    ) : (
                      <Col className={classes.root}>
                        <Button type="submit" className="form-btn mb-4 mt-4">
                          Save and Continue
                        </Button>
                      </Col>
                    )}
                  </form>
                );
              }}
            </Formik>
          }
        </Row>
      </Container>
    );
  }
};

export default InsuranceDetials;
