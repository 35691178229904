import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
import { registerUserAction } from "../../../../Redux/Action/AuthAction";
import { connect } from "react-redux";

import classes from "../signUp.module.css";
import { Button, CircularProgress } from "@material-ui/core";
import SnackBarMessages from "../../../UI/Snackbar/Snackbar";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import InputPassword from "../../../UI/Input/InputPassword";
class Signin extends Component {
  constructor(props) {
    super();
    this.state = {
      error: undefined,
      status: "",
      isLoading: false,
      message: "",
      userDetails: JSON.parse(localStorage.getItem("signupDetails2")),
      showPassword: false,
      appDetails: JSON.parse(localStorage.getItem('appDetails'))
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.signupResponse !== this.props.signupResponse) {
      if (this.props.signupResponse?.message) {
        this.setState({
          error: this.props.signupResponse.message,
          isLoading: false,
          message: this.props.signupResponse.message,
        });
      }
    }
    if (
      prevProps.signupResponse.response !== this.props.signupResponse.response
    ) {
      this.setState({
        status: this.props.signupResponse.response.status,
        isLoading: false,
        message: "Code sent to your email. Pls check your spam folder also",
      });
      localStorage.removeItem("signupDetails");
      localStorage.removeItem("signupDetails2");
      localStorage.removeItem("appDetails");
      this.props.history.push("/verify-user-code", {
        email: this.props.signupResponse.response.data.email,
        comeBack:
          this.props.location.state.comeBack !== undefined ? true : undefined,
      });
    }
  }

  handleClickShowPassword(event) {
    event.preventDefault();
    return this.setState({ ...this.state, showPassword: !this.state.showPassword });
  }

  render() {
    const { history } = this.props;
    let formData = this.props.location.state.values;

    const handleLoginNavigation = () => {
      history.push("/login", {
        comeBack:
          this.props.location.state.comeBack !== undefined ? true : undefined,
      });
    };

    return (
      <Container fluid>
        {this.state.error && (
          <SnackBarMessages
            open={true}
            type="error"
            position="top-right"
            timing={5000}
            message={this.state.message}
          />
        )}

        <Row>
          <Col xs={6}>
            <div className={classes["sign-up-page-2"]}>
              <span className={classes["circle"]}></span>
              <span className={classes["circle-text"]}>2 of 2</span>
            </div>
          </Col>
          <Col xs={6}>
            <div className={`mt-4 ${classes["signup-header"]}`}>
              <h4>Create Account</h4>
              <p>Basic Details</p>
            </div>
          </Col>
        </Row>

        <Formik
          validate={(values) => {
            let errors = {};
            if (!values.city) {
              errors.city = "City is Required";
            }
            if (!values.state) {
              errors.state = "State is Required";
            }
            if (!values.country) {
              errors.country = "Country is Required";
            }
            if (!values.agree) {
              errors.agree = "Accept Terms & Conditions is required";
            }

            const passwordRegex = /(?=.*[0-9])/;
            if (!values.password) {
              errors.password = "Password is Required";
            } else if (values.password.length < 8) {
              errors.password = "Password must be 8 characters long.";
            }
            // else if (!passwordRegex.test(values.password)) {
            //   errors.password = "Invalid password. Must contain one number.";
            // }
            return errors;
          }}
          validationSchema={Yup.object().shape({
            city: Yup.string().required("City is Required"),
            state: Yup.string().required("State is Required"),
            country: Yup.string().required("Country is Required"),
            password: Yup.string()
              .required("No password provided.")
              .min(8, "Password is too short - should be 8 chars minimum."),
            // .matches(/(?=.*[0-9])/, "Password must contain a number."),
            agree: Yup.boolean()
              .required("Accept Terms & Conditions is required")
              .oneOf([true], "Accept Terms & Conditions is required"),
            // agree: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')
          })}
          initialValues={{
            city: this.state.userDetails ? this.state.userDetails.city : "",
            state: this.state.userDetails ? this.state.userDetails.state : "",
            country: this.state.userDetails
              ? this.state.userDetails.country
              : "",
            password: this.state.userDetails
              ? this.state.userDetails.password
              : "",
            agree: this.state.userDetails
              ? this.state.userDetails.agree
              : false,
            advertising_url: this.state.appDetails?.app_advertising_url ? this.state.appDetails.app_advertising_url : '',
            is_advertising_user: this.state.appDetails?.app_advertising_url ? true : false
          }}
          onSubmit={(values, { setSubmitting }) => {
            let data = {
              ...formData,
              ...values,
            };
            localStorage.setItem("signupDetails2", JSON.stringify(values));
            this.props.dispatch(registerUserAction(data));
            setSubmitting(false);
            this.setState({ isLoading: true, error: false });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <div className="input-with-icon-placeholder position-relative">
                  <input
                    id="city"
                    name="city"
                    type="text"
                    placeholder="City"
                    className="form-input my-3 fontAwesome"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <i className="fas fa-city"></i>
                  {errors.city && touched.city && (
                    <div className="text-danger">{errors.city}</div>
                  )}
                </div>
                <div className="input-with-icon-placeholder position-relative">
                  <input
                    id="state"
                    name="state"
                    type="text"
                    placeholder="State"
                    className="form-input my-3 fontAwesome"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <i className="fas fa-map-marker-alt"></i>
                  {errors.state && touched.state && (
                    <div className="text-danger">{errors.state}</div>
                  )}
                </div>
                <div className="input-with-icon-placeholder position-relative">
                  <input
                    id="country"
                    name="country"
                    type="text"
                    placeholder="Country"
                    className="form-input my-3 fontAwesome"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <i className="fas fa-map-marker-alt"></i>
                  {errors.country && touched.country && (
                    <div className="text-danger">{errors.country}</div>
                  )}
                </div>
                <div className="input-with-icon-placeholder position-relative">
                  <InputPassword
                    id="password"
                    name="password"
                    placeholder="Password"
                    error={errors.password}
                    value={values.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.password}
                  />
                </div>

                <div
                  className={`${classes["terms-condition"]} text-center mt-4 row px-3`}
                >
                  <div className="col-1 pr-0 text-center">
                    <input
                      type="checkbox"
                      id="terms-condition"
                      name="agree"
                      className="position-relative"
                      value={values.agree}
                      checked={values.agree}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="col-11 pl-0">
                    <label htmlFor="terms-condition">
                      By logging in or creating an account, you agree to our{" "}
                      <br />
                      <Link to="/terms-of-use">Terms of Use</Link> and{" "}
                      <Link to="privacy-policy">Privacy Policy</Link>
                    </label>
                  </div>
                  <div className="col-12 text-center">
                    {errors.agree && touched.agree && (
                      <div className="text-danger">{errors.agree}</div>
                    )}
                  </div>
                </div>

                {this.state.error ? (
                  <div className="text-danger m-2">{this.state.error}</div>
                ) : (
                  <div className="text-success m-2">{this.state.error}</div>
                )}

                <div className="text-center">
                  {this.state.isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button type="submit" className="form-btn mt-2">
                      SIGNUP
                    </Button>
                  )}
                </div>
                <h4 className={`${classes["login-createOne"]} my-3`}>
                  <span>Already Registered? </span>

                  <button
                    className="login-link-text"
                    onClick={handleLoginNavigation}
                  >
                    Sign-in
                  </button>
                </h4>
              </form>
            );
          }}
        </Formik>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signupResponse: state.signup,
  };
};

export default connect(mapStateToProps, null)(Signin);
