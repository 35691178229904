import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// import Toothlens from "../../../../assets/Dashboard/teeth.png";
// import MechineLearning from "../../../../assets/Dashboard/machine-learning.png";
// import Cavity from "../../../../assets/Dashboard/single-teeth.png";
import ScanTooth from "../../../../assets/Dashboard/Drawer/scan-tooth.svg";
import Tooth from "../../../../assets/Dashboard/Drawer/tooth.svg";
import MachineLearning from "../../../../assets/Dashboard/Drawer/machine-learning.svg";

import Header from "../../../UI/Header/Header";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <Container>
      <Header title="About Us" routeRedirection="/dashboard" />

      <Row className="margin-top-70px pt-3">
        <Col>
          <Row className="primary-bg mx-auto border-radius-10px common-box-shadow mt-1 mb-4 min-height-9rem">
            <Col xs={3} className="my-auto text-center">
              <img src={ScanTooth} alt="tooth" className="pl-2" />
            </Col>
            <Col xs={9} className="my-auto">
              <p className="text-white font-size-18">
                Toothlens proprietary algorithm scans your tooth photos
              </p>
            </Col>
          </Row>
          <Row className="bg-pink mx-auto border-radius-10px common-box-shadow my-4 min-height-9rem">
            <Col xs={3} className="my-auto text-center">
              <img src={MachineLearning} alt="tooth" className="pl-2" />
            </Col>
            <Col xs={9} className="my-auto">
              <p className="text-white font-size-18">
                Developed by experts in machine learning and dental
                professionals from US, India and Germany
              </p>
            </Col>
          </Row>
          <Row className="primary-bg border-radius-10px common-box-shadow mx-auto my-4 min-height-9rem">
            <Col xs={3} className="my-auto">
              <img src={Tooth} alt="tooth" className="pl-2" />
            </Col>
            <Col xs={9} className="my-auto">
              <p className="text-white font-size-18">
                Can locate tooth cavities, visible tartar from tooth photos
              </p>
            </Col>
          </Row>

          {/* <Row className="border-radius-10px common-box-shadow mx-auto my-4 p-3">
            <Col xs={12} className="my-auto">
              <p className="font-size-18">
              Operational Address No1/1,Aditi,Anandapuram, Mylapore
                Chennai-600004 India <br />
                <span className="my-2 d-inline-block">
                  Contact Number(India)- <br />
                  <b className="font-size-18">+91-9841143765</b>
                  <br />
                  Contact Number(US)-
                  <br />
                  <b>+1 408 598 1944</b>
                </span>
              </p>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </Container>
  );
};
export default AboutUs;
