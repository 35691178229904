import React from 'react';
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import TeethImg from "../../../assets/Dashboard/reports/report-tooth.svg";

const EmptyInsights = (props) => {
    let history = useHistory();
    const navigateToQuestionnaire = () => {
      return history.push('/questionnaire', { userQuestionsData: [] })
    }

    return (
        <div className="col-12 mt-5 pt-5 text-center">
          <div className="mt-3">
            <p className="black-color font-size-16 mb-5">
              You don't seem to have checked all the dental health parameters
            </p>
            <div>
              <img src={TeethImg} alt="dental risk score" />
              <p className="black-color font-size-16 my-3">
                We are here to give you insights on taking care of your teeth
              </p>
            </div>

            {props.reportImages.length === 0 || props.isQuickScan ? (
              <Button className="my-5 form-btn font-size-18 font-weight-500">
                <Link
                  to="/camera-module-two"
                  className="text-white w-100 h-100"
                >
                  Take Complete Scan
                </Link>
              </Button>
            ) : (
              <Button className="my-5 form-btn font-size-18 font-weight-500"
              onClick={navigateToQuestionnaire}>
                Take Questionnaire
              </Button>
            )}
          </div>
        </div>
    )
}

export default EmptyInsights;