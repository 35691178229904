import React, { useState } from "react";
import { Button, Dialog, DialogActions } from "@material-ui/core";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const IntroVideoModal = (props) => {
  const { onClose, open } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(open);
  const handleClose = () => {
    onClose();
    setIsOpenDialog(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isOpenDialog}
      className="rating-container"
    >
      <div className=" mb-3">
        <a href="https://youtu.be/pwMO4YGgdqg">
          <h2 className="text-center font-size-20 primary-color my-3">
            Intro Video
          </h2>
        </a>
        <div className="col-12 my-3">
          <ReactPlayer
            url={"https://youtu.be/pwMO4YGgdqg"}
            controls
            playing={true}
            className="w-100"
            style={{ height: "500px" }}
          />
        </div>
      </div>
      {/* <DialogActions> */}
        <div className="col-12">
       
       <Link to="/camera-module-two">
                   <div className="d-flex w-5 justify-content-center align-items-center my-1"><h4>
                    <b>Next</b></h4></div> 
                  </Link>
      
       
        
        </div>
      {/* </DialogActions> */}
    </Dialog>
  );
};

export default IntroVideoModal;