import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";

import {
  getInsightsContents,
  getLatestReport,
} from "../../../Redux/Services/AuthService";

import RatingDialog from "../Dialog/RatingDialog";
import Header from "../../UI/Header/Header";
import NoteText from "../../UI/Note/NoteText";
import EmptyInsights from "../../UI/EmptyContents/EmptyInsights";
import {
  CavityContents,
  TartarContents,
  TartarZeroScoreContents,
} from "../../Utils/Insights/InsightsTartarAndCavityContents";
import SliderCard from "../../UI/Slider/SliderCard";
import {
  CAVITY_IMG_URL,
  TARTAR_IMG_URL,
} from "../../../Redux/Services/BaseUrl";
import InsightsCavityTartarCardSlider from "../../UI/Slider/InsightsCavityTartarCardSlider";
import "./insights.css";
import CavityTartarSkeleton from "../../UI/skeleton/cavityTartarSkeleton";

const Insights = () => {
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [reportImages, setReportImages] = useState([]);
  const [insightsContents, setInsightsContents] = useState("");
  const [isOpenRatingDialog, setIsOpenRatingDialog] = useState(false);
  const [dialogSelectedValue, setDialogSelectedValue] = useState("");
  const [reportScores, setReportScores] = useState("");
  const [reportScoreImg, setReportScoreImg] = useState([]);
  const [reportData, setReportData] = useState('');
  // Cavity and Tartar contents
  const tartarContents = TartarContents;
  const tartarZeroScoreContents = TartarZeroScoreContents;
  const cavityContents = CavityContents;

  const handleClickOpen = () => {
    setIsOpenRatingDialog(true);
  };

  const handleClose = (value) => {
    setIsOpenRatingDialog(false);
    setDialogSelectedValue(value);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      getLatestReport()
        .then((res) => {
          setReportData(res.data.data[0]);
          setReportImages(res.data.data[0].report_score);
          setReportScores(res.data.data[0].report_score[0]);
          const reportScore = Object.keys(res.data.data[0].report_score[0]);
          // deleting last value from reportScore
          reportScore.splice(reportScore.length - 1, 1);
          setReportScoreImg(reportScore);
          // cavity tartar score
          const tartarScore = res.data.data[0].report_score[0].overall_score[0];
          const cavityScore = res.data.data[0].report_score[0].overall_score[1];
          getInsightsContents(cavityScore, tartarScore)
            .then((res1) => {
              const responseObj = res1.data?.data;
              // setIsSkeletonLoading(false);
              if (res1 && res1.status === 200 && (responseObj.habits.length > 0 || responseObj.medical.length > 0)) {
                setInsightsContents(responseObj);
                setTimeout(() => {
                  handleClickOpen();
                }, 40000);
              }
            })
            .catch((err) => {
            });
        })
        .catch((err) => {
          setIsSkeletonLoading(false);
        });
    }
    setIsSkeletonLoading(false);

  }, []);

  return (
    <Container className="insights-container dental-risk-bg">
      <Header title="Insights" routeRedirection="/dashboard" />
      {isSkeletonLoading && <CavityTartarSkeleton />}

      {
        !reportData.is_quick_scan && (
          <>
            {reportImages.length > 0 && insightsContents ? (
              <Row className="margin-top-70px">
                <Col xs={12} className="px-0 mt-2">
                  <div>
                    {reportImages.length > 0 && (
                      <>
                        {/* Cavity section */}
                        <>
                          <div className="px-3 black-color font-size-22 mb-3 font-weight-500">
                            Cavity
                          </div>
                          {+reportScores["overall_score"][1] > 0 && (
                            <div className="shadow bg-white m-3 py-3 border-radius-15px">
                              <InsightsCavityTartarCardSlider
                                imgUrl={CAVITY_IMG_URL}
                                reportScoreImg={reportScoreImg}
                                reportScores={reportScores}
                                isTartar={false}
                              />
                            </div>
                          )}
                          {+reportScores.overall_score[1] < 1 && (
                            <div className="habits-medical-card mb-3">
                              <SliderCard
                                content={cavityContents.low}
                                cssClass="primary-bg text-white"
                              />
                            </div>
                          )}
                          {+reportScores.overall_score[1] >= 1 && (
                            <div className="habits-medical-card mb-3">
                              <SliderCard
                                content={cavityContents.high}
                                cssClass="primary-bg text-white"
                              />
                            </div>
                          )}
                        </>

                        {/* Tartar section */}
                        <>
                          <div className="px-3 black-color font-size-22 mb-4 font-weight-500">
                            Tartar
                          </div>
                          {+reportScores["overall_score"][0] > 0 && (
                            <div className="shadow bg-white m-3 py-3 border-radius-15px">
                              <InsightsCavityTartarCardSlider
                                imgUrl={TARTAR_IMG_URL}
                                reportScoreImg={reportScoreImg}
                                reportScores={reportScores}
                                isTartar={true}
                              />
                            </div>
                          )}

                          {+reportScores.overall_score[0] === 0 && (
                            <div className="habits-medical-card mb-3">
                              <SliderCard
                                content={tartarZeroScoreContents}
                                cssClass="primary-bg text-white"
                              />
                            </div>
                          )}

                          {+reportScores.overall_score[0] >= 1 &&
                            +reportScores.overall_score[0] <= 5 && (
                              <div className="habits-medical-card mb-3">
                                <SliderCard
                                  content={tartarContents.low}
                                  cssClass="primary-bg text-white"
                                />
                              </div>
                            )}

                          {+reportScores.overall_score[0] > 5 &&
                            +reportScores.overall_score[0] <= 10 && (
                              <div className="habits-medical-card mb-3">
                                <SliderCard
                                  content={tartarContents.medium}
                                  cssClass="primary-bg text-white"
                                />
                              </div>
                            )}

                          {+reportScores.overall_score[0] > 10 && (
                            <div className="habits-medical-card mb-3">
                              <SliderCard
                                content={tartarContents.high}
                                cssClass="primary-bg text-white"
                              />
                            </div>
                          )}
                        </>
                      </>
                    )}

                    {/* Habit content */}
                    <div className={`mb-3 habits-medical-card`}>
                      {insightsContents && insightsContents.habits.length > 0 && (
                        <>
                          <h5 className="px-3 black-color font-size-22 my-4">
                            Habits
                            {/* <span className="font-size-20 grey-color">
                            ({insightsContents.habits.length})
                          </span> */}
                          </h5>
                          <SliderCard
                            content={insightsContents.habits}
                            cssClass="primary-bg text-white"
                          />
                        </>
                      )}
                    </div>

                    {/* Medical content */}
                    <div className="habits-medical-card">
                      {insightsContents && insightsContents.medical.length > 0 && (
                        <>
                          <h5 className="px-3 black-color font-size-22 my-4">
                            Medical
                            {/* <span className="font-size-20 grey-color">
                            {" "}
                            ({insightsContents.medical.length})
                          </span> */}
                          </h5>
                          <SliderCard content={insightsContents.medical} />
                        </>
                      )}
                    </div>
                  </div>
                </Col>

                <Col className="mt-5">
                  <NoteText />
                </Col>
              </Row>
            ) : null}

            {/* Rating dialog box */}
            {reportImages.length > 0 && (
              <Row>
                <RatingDialog
                  selectedValue={dialogSelectedValue}
                  open={isOpenRatingDialog}
                  onClose={handleClose}
                />
              </Row>
            )}

          </>
        )
      }

      {/* when there is no report list */}
      {reportImages.length === 0 || !insightsContents || reportData.is_quick_scan ? (
        <EmptyInsights reportImages={reportImages} isQuickScan={reportData.is_quick_scan} />
      ) : null}
    </Container>
  );
};

export default Insights;
