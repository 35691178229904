import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import SuccessTeeth from "./../../../assets/cameraModule/Success.svg";
import FailedTeeth from "./../../../assets/cameraModule/Failed.svg";
import { useHistory } from "react-router";
import { connect } from "react-redux";

const MessageModal = (props) => {
  let history = useHistory();
  let userId = JSON.parse(localStorage.getItem("user"))?.user._id;

  const redirectToDashboard = async () => {
    localStorage.removeItem('report');
    if (props.isSuccess) {
      localStorage.setItem('isGradeExplainer', true);
    }
    props.resetReport();
    props.removeAllImages();
    props.resetReportUpload();
    localStorage.removeItem("reportFolderName");
    return history.push("/dashboard");
  }

  return (
    <Row className="mx-0">
      <Col xs={12} className="d-flex justify-content-around mx-auto px-0">
        <div className="all-well-modal p-3">
          <div className="text-center">
            <img src={props.isSuccess ? SuccessTeeth : FailedTeeth} alt="SuccessTeeth" />
          </div>
          <div className="text-center">
            <h3 className="login-createOne my-3 mx-4 font-size-16">
              <span>
                {
                  props.isSuccess ? `${props.isQuickScan ? props.message : 'Thanks for submitting your photos.Go to Dashboard to see your reports.'}` :
                    'Sorry!! AI is experiencing some difficulty. Please rescan after 5 minutes'
                }
              </span>
              {/* {
                !props.isQuestionnaireGiven && <span>Please take questionnaire to generate report</span>
              } */}
              {/* {
                props.isSuccess && !props.isQuickScan && <div className="mt-2 font-size-13">
                  <b>NOTE: </b>To view report check email-you will get a pdf report in email
                </div>
              } */}
            </h3>
          </div>
          <div className="text-center mb-3">
            <button
              type="submit"
              className="modal-signup-btn mt-2"
              onClick={redirectToDashboard}
            >
              Back to dashboard
            </button>
            {/* <Button
                  className="position-relative left-0 text-center reshoot-btn reshoot-left-icon rounded-pill primary-bg"
                  variant="primary"
                  size="sm"
                >
                  <i className="fas fa-redo font-size-22 text-white"></i>
                </Button> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapDespatchToProps = (dispatch) => {
  return {
    resetReport: () => {
      dispatch({ type: "USER_REPORT_UPLOAD_RESET" });
    },
    removeAllImages: (imageData) => {
      dispatch({ type: "REMOVE_ALL IMAGES" });
    },
    resetReportUpload: (report) => {
      dispatch({ type: "USER_REPORT_RESET" });
    },
  };
};

export default connect(null, mapDespatchToProps)(MessageModal);
