import React from "react";
import { Skeleton } from "@material-ui/lab";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Col, Row } from "react-bootstrap";

const CavityTartarSkeleton = () => {
    return (
        <Row className="mx-0 justify-content-center">
            <Col xs={12} className="px-4 text-center my-3">
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton animation="wave" variant="text" />
                <Skeleton
                    sx={{ bgcolor: 'grey.900' }}
                    variant="rect"
                    height={118}
                />

                <Card sx={{ maxWidth: 345, m: 2 }}>
                    <CardHeader
                        action={
                            <IconButton aria-label="settings">
                                <Skeleton animation="wave" />
                            </IconButton>
                        }
                        title={
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />
                        }
                        subheader={<Skeleton animation="wave" height={10} width="40%" />}
                    />
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rect" />
                    <CardContent>
                        <React.Fragment>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </React.Fragment>
                    </CardContent>
                </Card>

            </Col>

            <Col xs={12} className="px-4 text-center my-3">
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton
                    sx={{ bgcolor: 'grey.900' }}
                    variant="rect"
                    height={118}
                />

                <Card sx={{ maxWidth: 345, m: 2 }}>
                    <CardHeader
                        action={
                            <IconButton aria-label="settings">
                                <Skeleton animation="wave" />
                            </IconButton>
                        }
                        title={
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />
                        }
                        subheader={<Skeleton animation="wave" height={10} width="40%" />}
                    />
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rect" />
                    <CardContent>
                        <React.Fragment>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </React.Fragment>
                    </CardContent>
                </Card>

            </Col>

        </Row>
    )
}

export default CavityTartarSkeleton