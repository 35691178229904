import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PrivacyPolicyImg from "../../../../assets/Dashboard/Drawer/privacy-policy.svg";
import Header from "../../../UI/Header/Header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Container>
        <Header title="Privacy policy" routeRedirection="/dashboard" />
        <Row className="margin-top-70px">
          <Col>
            <img
              src={PrivacyPolicyImg}
              alt="head"
              className="mx-auto d-block my-5"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="privacyPolicy-header mb-3">Introduction</h4>
            <p className="privacyPolicy-content">
              We at Toothlens care about your privacy. We are fully committed to
              protecting and safeguarding the personal data you share with us
              when you use our service. In this Privacy Policy, we explain what
              kind of data we use and how we use it. If you have any questions,
              do not hesitate to contact us via the contact details listed at
              the end of this Policy.
            </p>
            <p className="privacyPolicy-content">
              For further information about our use of cookies, please refer to
              our cookie policy . We might
              amend this Privacy Policy from time to time. Visit this page
              regularly to understand what we do. If we make changes relevant to
              your consent and underlying information, we will always notify you
              before you use our service.
              {/* <Link to="/privacy-policy">Cookie Policy</Link> */}
            </p>
            <p className="privacyPolicy-content">
              If you disagree with our processing of personal data as described
              in this Privacy Policy, you cannot continue using our Services. If
              you agree with our Privacy Policy, we now welcome you to our
              service, and you're ready to start making assessments. Below you
              can find summaries of the information in this Privacy Policy.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              The personal data Toothlens collects
            </h4>
            <p className="privacyPolicy-content">
              To monitor your dental health, information is necessary for us to
              provide you with our Services. You can sign up for our service in
              the app using your email address. We collect information about the
              device you use to access our App or Websites. The type of
              information we collect can, for example, include the style of your
              device, the software you use, location, device language, and your
              IP address. In addition to this, we collect the data you provide
              in the App, including the questionnaire's answers regarding your
              dental profile and the pictures you take.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Why Toothlens collects your data
            </h4>
            <p className="privacyPolicy-content">
              We use your data to assist you in the best possible way. We need
              specific data to provide you with our full service and help you
              with your health journey. We may also use your data for research
              purposes to improve our service. Furthermore, your information may
              be used for customer service, marketing, communications, and legal
              purposes.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Toothlens and third parties
            </h4>
            <p className="privacyPolicy-content">
              Toothlens has third-party service providers who help us provide or
              improve our service; this includes service providers, payment
              providers, financial institutions, business partners, or research
              institutions.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Toothlens and social media
            </h4>
            <p className="privacyPolicy-content">
              We have share and like buttons to enable you to share our content
              with your network on our website.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Privacy, data security, and data retention
            </h4>
            <p className="privacyPolicy-content">
              Toothlens has implemented various measures and procedures to
              safeguard your data, as stipulated by US/ European data protection
              law. We will retain your data for 12 months after you unsubscribe
              from our service.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Personal data of children
            </h4>
            <p className="privacyPolicy-content">
              Our service can only be used when you have reached eighteen (18)
              years or older.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Your rights – access to your information
            </h4>
            <p className="privacyPolicy-content">
              At any time, you can request to review, correct, delete, obtain
              your data. You are also entitled to withdraw consent for the
              processing of the personal data we hold of you. You can do this by
              email, using the addresses listed below.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Responsible party for the processing
            </h4>
            <p className="privacyPolicy-content">
              Toothlens is the owner and operator of the service and is the
              controller of personal data processed via the Website and the App.
              Please find the full explanation of how we process your data
              below.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              The personal data Toothlens collects
            </h4>
            <h4 className="privacyPolicy-header mb-3">
              Personal data you provide to us
            </h4>
            <p className="privacyPolicy-content">
              The data you provide to us is collected and used ("processed") to
              provide you with our best service. The minimum information you are
              asked to provide us is your email address to access our service.
              In our App, you can provide further information regarding your
              dental health and risk profile. We also collect the pictures you
              take of your teeth lesions and how you store, organize, and
              comment on them. Without your explicit consent, we cannot store
              this type of information, and we cannot provide you with our
              service.
            </p>
            <p className="privacyPolicy-content">
              Your dental data will be shared automatically with the dental
              practice whose app URL you are using for the toothlens dental
              check .If you use the send to dentist function, you can fix an
              appointment and select location with the dental practice
            </p>
            <p className="privacyPolicy-content">
              Other information we collect is your payment information and the
              information you provide to complete your profile: your first name
              and last name, profile picture, gender, date of birth, location,
              phone number, language.
            </p>
            <p className="privacyPolicy-content">
              When you use our Toothlens Check services and submit a picture of
              your teeth for analysis by our service, we store the pictures and
              collect your assessment information. We do this to be able to
              assist you with your health journey. We will ask for your explicit
              consent to store this type of information before using such
              service; without your consent, we cannot provide you with our
              service. As part of your health journey, we may send you follow-up
              emails regarding the provided advice and to assist you by
              reminding you to seek medical attention when necessary.
            </p>
            <p className="privacyPolicy-content">
              When you use our App, we also collect data that identifies your
              mobile device and your use of the platform. The information we
              store includes notification access, device-specific settings and
              characteristics, system activity, location details, IP address,
              language settings, app crashes, and other device event
              information, access data, and times of your app usage. We collect
              information when you give us feedback via your Apple App Store,
              Google Play Store, or the questionnaire.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Personal information you provide us regarding the health of others
            </h4>
            <p className="privacyPolicy-content">
              Our service is exclusively meant for your personal use. If you
              want to help others, please do so by encouraging them to download
              their version of Toothlens. This will help them with their health
              journey and ensures that any health-related messages will be
              delivered to the right person.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Automatically collected personal data
            </h4>
            <p className="privacyPolicy-content">
              During your visit to our Website or App, we automatically collect
              certain information about you, your visit to the service, and the
              device you are using. This data includes data on the hardware,
              software, operating system, internet browser, IP address, language
              and application settings, and version.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Why Toothlens collects your data
            </h4>
            <p className="privacyPolicy-content">
              We use your data to assist you in the best possible way; your data
              may be used for the following reasons:
            </p>
            <p className="privacyPolicy-content">
              Health journey: The main reason why we collect your data is to
              supply you with our core service: assistance with your dental
              health journey and assist you with the early detection of poor
              dental hygiene
            </p>
            <p className="privacyPolicy-content">
              Management and improvement of our Services: We use your
              information to manage our Website, App, and business and to
              improve our services continuously.
            </p>
            <p className="privacyPolicy-content">
              Customer Service: Our customer service is here to help you, and we
              use your data to do so. We may send you email notifications and
              in-App messages relating to the analysis results; this includes
              emails in which we provide you with information and ask you to
              provide us with information about possible follow-up actions.
            </p>
            <p className="privacyPolicy-content">
              Marketing: To keep you informed, we may send you communications
              relating to our business by email or other contact details you
              provided to us. If you submit personal information for publication
              on our website, we will use it by the license you grant.
            </p>
            <p className="privacyPolicy-content">
              Communications: We will send you communication regarding the
              assessments you make via the App. If you opt-in for our mailing
              list, we may send you non-commercial communications, including our
              newsletter. When you use our services, we may send you a
              questionnaire or invite you to review your experiences with our
              service. We also may get in touch with you regarding feedback,
              inquiries, and complaints you made regarding our Website and App.
            </p>
            <p className="privacyPolicy-content">
              Research activities:  By uploading your images in the App, you
              explicitly consent to the images being processed to provide the
              services and be used anonymously to research and test our
              services. As such, your images may be reviewed by our employees or
              third-party consultants who work for us and who are bound by
              strict confidentiality.
            </p>
            <p className="privacyPolicy-content">
              Legal purposes: In certain cases, we may need to use your
              information to handle and resolve legal disputes, for regulatory
              investigations and compliance, or to enforce the terms of use of
              the service as reasonably expected.
            </p>
            <p className="privacyPolicy-content">
              We collect and process your data based on the following legal
              grounds: Contractual necessity: To fulfill the contract you enter
              into with us when you use our Services, we must process some
              essential information. When you wish to use one of our paid
              services, we may need to process your email address, pictures of
              teeth, risk assessment, and payment information. Legitimate
              interests: We are committed to improving and growing our service.
              Some of your data can improve and promote our Service and Website;
              other data we may need for administrative, legal, or anti-fraud
              activities.
            </p>
            <p className="privacyPolicy-content">
              Legal obligations: We have to comply with certain laws and
              (country-specific) regulations. To comply with these, we need
              information about your location and date of birth. Consent: For
              certain promotional and marketing activities, we may ask
              additional consent. When you wish to withdraw your consent, please
              contact us via the contact details at the bottom of this Policy.
            </p>
            <p className="privacyPolicy-content">
              Explicit consent: Data regarding the health of your teeth and risk
              assessments is a more sensitive category of data. To lawfully
              process this data for you, we will ask for your explicit consent
              before assisting you. When you wish to withdraw your consent,
              please contact us via the contact details at the bottom of this
              Policy. When you withdraw your consent, we will not provide you
              with our Services, and you should discontinue using our Services.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Toothlens and third parties
            </h4>
            <p className="privacyPolicy-content">
              For some specific reasons, Toothlens may share your data with
              carefully-selected third parties for the use of digital tools for
              digital automation purposes
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Toothlens and social media
            </h4>
            <p className="privacyPolicy-content">
              Toothlens uses social media for various reasons. On our Website,
              we have integrated social media like and share buttons (Facebook,
              Twitter, LinkedIn, Reddit), which allows you to share the content
              you like directly with your network. The social media plugins may
              process your data when you choose to share or like our website's
              content with your network. Toothlens does not control and is not
              responsible for the processing of personal data by these networks.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Data privacy, security, and data retention
            </h4>
            <h4 className="privacyPolicy-header mb-3">Privacy</h4>
            <p className="privacyPolicy-content">
              All data you provide to us and collected from you is stored on
              secure cloud servers (the Servers) operated and managed by AWS,
              Amazon Web Services, USA. As a result, your personal information
              may be transferred to and stored at a destination outside your
              country. By submitting your personal information, you agree to the
              transfer of your personal information to the servers. Personal
              information may also be processed by staff or by other third-party
              service providers operating outside your country who work for us.
              We take such steps as necessary for the circumstances to ensure
              that any third-party service providers treat your data securely
              and according to applicable laws.
            </p>

            <h4 className="privacyPolicy-header mb-3">Data security</h4>
            <p className="privacyPolicy-content">
              All data you provide through the App and the Website is encrypted
              when transferred to or from the servers using industry-standard
              encryption (TLS). Furthermore, data is stored in encrypted form
              when at rest on the servers.
            </p>
            <p className="privacyPolicy-content">
              We store your account information and your digital images in
              different data stores. The cloud server infrastructure is
              protected using firewalls and monitoring.
            </p>
            <p className="privacyPolicy-content">
              We work with appropriate procedures to prevent unauthorized access
              to and misuse of your data. Toothlens uses appropriate procedures
              and business systems to safeguard your data. Furthermore, we use
              security procedures and technical and physical restrictions to
              access and use personal data on our servers. Only authorized
              personnel can access your data.
            </p>

            <h4 className="privacyPolicy-header mb-3">Data retention</h4>
            <p className="privacyPolicy-content">
              Toothlens will destroy or de-identify your personal information
              once it is no longer required for the purpose or purposes for
              which it was collected. If you terminate the Services and delete
              your account, we will retain your personal information for 12
              months, after which we will delete your data. We will retain the
              images you have uploaded before termination in an anonymous form
              only for medical, clinical, and commercial research and for
              testing the Services. We will ask for your consent to process your
              data anonymously for research purposes. We will retain
              (electronic) documents containing personal information:
            </p>
            <ul className="px-3">
              <li>to the extent that we are required to do so by law;</li>
              <li>
                if we believe that the documents may be relevant to any ongoing
                or prospective legal proceedings; and
              </li>
              <li>
                establish, exercise, or defend our legal rights (including
                providing information to others for fraud prevention and
                reducing credit risk).
              </li>
            </ul>

            <h4 className="privacyPolicy-header mb-3">
              Personal data of children
            </h4>
            <p className="privacyPolicy-content">
              Our service can only be used when you have reached eighteen (18)
              years or when you are older. When we identify personal information
              of children younger than eighteen (18) years old, we shall delete
              the data based on our legal obligations.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Your rights – access to your information
            </h4>
            <p className="privacyPolicy-content">
              Upon your request, we will provide you with access to your data
              that is held by us. We will provide the data to you in a
              structured, understandable, and machine-readable way. We request
              that you provide us with appropriate evidence of your identity
              (for this purpose, we will usually accept a photocopy of your
              passport plus an original copy of a utility bill showing your
              current address). We will require you to make your identification
              number and picture invisible to us.
            </p>
            <p className="privacyPolicy-content">
              In your request for access, we also request that you identify, as
              clearly as possible, the type(s) of information you wish to have
              access to. We will comply with your request to provide access to
              your personal information within 30 days. If you agree, we may
              charge you our reasonable costs to supply you with access to this
              information.
            </p>
            <p className="privacyPolicy-content">
              At any time, you can request to review, correct, delete, or obtain
              your data. You can also withdraw consent for the processing of the
              personal data we hold of you. You can make such a request by
              email, using the addresses listed below. You also have the right
              to contact the Data Protection Authority when you have concerns
              about your rights or feel that we are unlawfully processing your
              data.
            </p>
            <p className="privacyPolicy-content">
              You may oppose the processing of personal information concerning
              you, even if they are relevant to the collection's purpose. You
              may instruct us at any time not to process your data for marketing
              purposes, and we will provide you with an opportunity to opt-out
              of the use of your personal information for marketing purposes.
            </p>

            <h4 className="privacyPolicy-header mb-3">
              Responsible party for the processing of your data
            </h4>
            <p className="privacyPolicy-content">
              The Websites and the Application are owned and operated by
              Toothlens Healthcare registered in India and the US.You can
              contact us by writing to the business address given above, by
              using our Website contact form or the feedback form in the App, or
              by sending an email to{" "}
              <a href="mailto:support@toothlens.com">support@toothlens.com</a>
            </p>
            <p className="privacyPolicy-content">
            <b>Registered Address</b> <br />
            Toothlens Inc., <br/>
            651 N Broad St <br/>
            Suite 201 <br/>
            Middleton <br/>
            DE 19709 <br/>
            USA
          </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default PrivacyPolicy;
