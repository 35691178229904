import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  Radio,
  RadioGroup,
  FormLabel,
  TextField,
} from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import SearchIcon from "../../../../assets/icon/Search-Icon.svg";
import BranchIcon from "../../../../assets/icon/branch-icon.svg";
import Header from "../../../UI/Header/Header";
import ConfirmationBranchPopup from "./ConfirmationPopup";
import Swal from "sweetalert2";
import { FolderOpenOutlined } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getBranches } from "../../../../Redux/Services/branchService";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { storeSentToDentist } from "../../../../Redux/Services/ReportService";

const SelectBranches = (props) => {
  const [reportId, setReportId] = useState(props.match.params.reportId);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [userId, setUserId] = useState(props.location.state.userId);
  const [isConfirmationPopup, setIsConfirmationPopup] = useState(false);
  const [status, setStatus] = useState(true);
  const [statusSelected, setStatusSelected] = useState(false);
  const [branchesData, setBranchData] = useState([]);
  const [branchDetails, setBranchDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branchId, setBranchId] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const [preferredAppointmentDate, setPreferredAppointmentDate] = useState("");
  const [preferredAppointmentTime, setPreferredAppointmentTime] = useState("");
  const [showBranches, setShowBranches] = useState(false);

    useEffect(() => {
        getBranches()
            .then((res) => {
                res.data.data.length<=1?setShowBranches(false):setShowBranches(true)
                if (res?.data?.data.length > 0) {
                    setBranchData(res.data.data);
                    setBranchDetails(res.data.data);
                }
            });
    }, []);

  const handleChangeBranch = (event) => {
    const branchId = event.target.value;
    setBranchId(branchId);
    const branchIndex = branchDetails.findIndex(
      (branch) => branch._id === branchId
    );
    if (branchIndex > -1) {
      setSelectedBranch(branchDetails[branchIndex]);
    }
  };

  const onChangeValue = (event) => {
    setStatus(event.target.value);
    setStatusSelected(true);
  };

  const submitBranchHandler = async () => {
    setLoading(true);
    let new_patient = JSON.parse(status);
    const reportData = {
      new_patient,
      user: userId,
      report: reportId,
      branch: showBranches ? selectedBranch._id : branchesData[0]._id,
      // preferred_appointment_date: preferredAppointmentDate,
      // preferred_appointment_time: preferredAppointmentTime ? preferredAppointmentTime.toTimeString() : ''
    };
    storeSentToDentist(reportData)
      .then((res) => {
        setLoading(false);
        setIsConfirmationPopup((prevState) => !prevState);
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire("Something went wrong", "", "error");
      });
  };
  const onCloseDialog = () => {
    setIsConfirmationPopup(false);
  };

  const handleChange = (event) => {
    const branchesList = [...branchesData];
    let branches = [];
    if (event.target.value && event.target.value.length > 0) {
      branches = branchesList.filter((item) => {
        return item.location
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setBranchData(branches);
    } else {
      setBranchData(branchDetails);
    }
  };

  return (
    <Container fluid className="select-branches-container">
      <Header title="connect with doctor" routeRedirection={`/total-test`} />
      {isConfirmationPopup && (
        <ConfirmationBranchPopup
          open={isConfirmationPopup}
          onClose={onCloseDialog}
        />
      )}
      <Row className="px-3 margin-top-70px pt-4">
        {showBranches && (
          <div>
            <FormControl className="mt-4" fullWidth variant="standard">
              <Input
                id="standard-adornment-amount"
                placeholder="Search branch"
                onChange={handleChange}
                startAdornment={
                  <InputAdornment className="mb-2" position="start">
                    <img src={SearchIcon} alt="SearchIcon" />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl className="my-4 w-100">
              <RadioGroup
                row
                aria-label={`branch`}
                name={`branch`}
                value={branchId}
                onChange={handleChangeBranch}
              >
                {branchesData.map((data, index) => {
                  return (
                    <label
                      key={`option${index}`}
                      className="w-100 questions-option"
                    >
                      <FormControlLabel
                        className="profile-radios"
                        value={`${data._id}`}
                        control={<Radio color="primary" />}
                        label={`${data.city?data.city:data.location}, ${data.state?data.state:""}${data.zip_code?data.zip_code:""}`}
                      />
                    </label>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        )}

        <FormControl>
          <FormLabel className="mt-3" id="demo-radio-buttons-group-label">
            Please mention your status
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            row
            value={status}
            onChange={onChangeValue}
          >
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="New Patient"
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="Existing Patient"
            />
          </RadioGroup>
        </FormControl>
        {/* <FormControl className='w-100'>
                    <FormLabel className="mt-3" id="demo-radio-buttons-group-label">
                        Preferred Appointment Date (optional)
                    </FormLabel>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                    >
                        <KeyboardDatePicker
                            //   utils={DateFnsUtils}
                            minDate={new Date()}
                            className="form-input my-3 py-3 fontAwesome "
                            format="MM/dd/yyyy"
                            placeholder="Preferred Appointment Date"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            id="appointmentDate"
                            name="appointmentDate"
                            helperText={null}
                            inputProps={{ readOnly: true }}
                            //   onBlur={handleBlur}
                            value={preferredAppointmentDate}
                            onChange={(value) => setPreferredAppointmentDate(value)}
                            InputAdornmentProps={{ position: "start" }}
                            open={isDatePickerOpen}
                            onOpen={() => setIsDatePickerOpen(true)}
                            onClose={() => setIsDatePickerOpen(false)}
                            TextFieldComponent={(props) => (
                                <TextField {...props} onClick={(e) => setIsDatePickerOpen(true)} />
                            )}

                        />
                    </MuiPickersUtilsProvider>
                </FormControl>

                <FormControl className='w-100'>
                    <FormLabel className="mt-3" id="demo-radio-buttons-group-label">
                        Preferred Appointment Time (optional)
                    </FormLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            className="form-input my-3 py-3 fontAwesome"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            id="appointmentTime"
                            name="appointmentTime"
                            helperText={null}
                            InputAdornmentProps={{ position: "start" }}
                            inputProps={{ readOnly: true }}
                            placeholder="Preferred Appointment Time"
                            value={preferredAppointmentTime}
                            open={isTimePickerOpen}
                            onChange={(value) => {
                                setPreferredAppointmentTime(value)
                            }}
                            onOpen={() => setIsTimePickerOpen(true)}
                            onClose={() => setIsTimePickerOpen(false)}
                            TextFieldComponent={(props) => (
                                <TextField {...props} onClick={(e) => setIsTimePickerOpen(true)} />
                            )}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl> */}

        {loading ? (
          <Col xs={12}>
            <div className="d-flex w-5 justify-content-center my-4">
              <CircularProgress />
            </div>
          </Col>
        ) : (
          <Button
            type="submit"
            disabled={!statusSelected}
            className="form-btn my-4"
            onClick={submitBranchHandler}
          >
            Confirm
            <span className="position-absolute right-20px">
              <img src={BranchIcon} alt="SearchIcon" />
            </span>
          </Button>
        )}
      </Row>
    </Container>
  );
};

export default SelectBranches;
