import React, { useEffect, useState } from "react";
import QRCode from 'qrcode';
import cssClasses from './QrCode.module.css';
// import Logo from "./../../assets/logo/logo.jpeg";
import { getAppsDetails,getBinaryDataOfClinicImage} from "../../Redux/Services/AppsService";

import Logo from '../../assets/logo/logo67x67.svg';

const QrCodePage = () => {
    const [src, setSrc] = useState('');
    const [loading,setLoading]=useState(true);
    const[logo,setLogo]=useState([])
    useEffect(() => {
        QRCode.toDataURL(window.location.origin).then((data) => {
            setSrc(data);
        });
        getAppsDetails(window.location.origin)
            .then((resData) => {
                if (resData.data.data !== 'No Data') {
                    QRCode.toDataURL(resData.data.data.app_url).then((data) => {
                        getBinaryDataOfClinicImage(resData.data.data.app_logo_file).then((res) => {
                            if (res.status == 200) {
                              const byteArray = new Uint8Array(res.data.data.binaryData.data); // Convert the array of integers to a byte array
                              const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Assuming it's a JPEG image
                              const imageUrl = URL.createObjectURL(blob);
                              localStorage.setItem('imageblob', JSON.stringify(res.data.data.binaryData.data));   
                              setLogo([imageUrl])
                              setLoading(false)                            } 
                          }).catch((err) => {
                            setLoading(true)
                          })
                        
                    });
                }
            })

    }, []);

    return (
        <div className={`container ${cssClasses['qr-container']}`}>
            <div className="row">
                <div className={`col-12 primary-bg`}>
                    <div className="row">
                        <div className="col-12 text-center d-flex align-items-center justify-content-center py-2">
                            <div className={`${cssClasses['logo']}`}>
                            { loading?  <img className={`${cssClasses['img']}`} src={Logo} alt="logo" width="50" />: <img className={`${cssClasses['img']}`} src={logo[0]} alt="logo" width="50" />}
                            </div>
                            <h1 className={`text-white mb-0 py-3 font-size-20 text-uppercase ml-4`}>
                                Toothlens
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5 px-5 text-center">
                    <p className="font-weight-500 font-size-20 text-uppercase">Thanks for visiting</p>
                    <p>Toothlens works on mobile devices only</p>
                    <p>Please scan to use the App or type <b>{window.location.hostname}</b> in your mobile browser.</p>
                </div>
                <div className={`col-12 text-center my-5`}>
                    <img className={`${cssClasses['qr-code-img']}`} src={src} alt="QR code img" />
                </div>
                {/* <div className="col-12 text-center mt-4">
                    <p className="my-2">Powered By</p>
                    <img className={`${cssClasses['logo']}`} src={Logo} alt="logo" />
                    <span className={`py-3 font-size-20 text-uppercase ml-2`}>
                        Toothlens
                    </span>
                </div> */}
            </div>
        </div>
    )
}

export default QrCodePage;