import { Button, Dialog, DialogActions } from '@material-ui/core';
import React from 'react';
import Step1 from '../../../assets/Instruction_GIF_s/Step-1.gif';
import Step2 from '../../../assets/Instruction_GIF_s/Step-2.gif';
import Step4 from '../../../assets/Instruction_GIF_s/Step-4.gif';
import Step9 from '../../../assets/Instruction_GIF_s/step-9.gif'
import Slider from "react-slick";
import { Card } from 'react-bootstrap';

const stepsData = [
    {
        image: Step1,
        text: 'Stand in front of mirror'
    },
    {
        image: Step2,
        text: 'Turn on back camera for better clarity'
    },
    {
        image: Step4,
        text: 'Now, take the picture inside of the mouth'
    },  {
        image: Step9,
        text: 'Or, You can ask another person to take the photo'
    }
]

const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    // variableWidth: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
            },
        },
    ]
};

const QuickScanTutorialDialog = (props) => {
    const { onClose, open, message } = props;

    const handleClose = () => {
        onClose(false);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open} className="rating-container">
            <div className="mb-3">
                <h2 className="text-center font-size-20 primary-color mt-3">INSTRUCTIONS FOR USE</h2>
                <Slider {...settings}>
                {stepsData.map((item, index) => {
                    return (
                        <div key={item} className="cavity-slider">
                            <Card className="cavity-card">
                                <Card.Img
                                    variant="top"
                                    src={item.image}
                                    alt="report images"
                                />
                            </Card>
                            <p className='my-3 text-center'>{item.text}</p>
                        </div>
                    );
                })}
            </Slider>
            </div>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default QuickScanTutorialDialog;