export const CavityAndTartarMarks = [
  {
    value: 0,
    label: "A",
  },
  {
    value: 25,
    label: "B",
  },
  {
    value: 50,
    label: "C",
  },
  {
    value: 75,
    label: "D",
  },
  {
    value: 100,
    label: "E",
  },
];

export const DentalRiskScoreMark = [
  {
    value: 0,
    label: "Low",
  },
  {
    value: 50,
    label: "Medium",
  },
  {
    value: 100,
    label: "High",
  },
];
