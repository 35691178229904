import React, { Component } from "react";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import * as Yup from "yup";

import { connect } from "react-redux";
import { forgotPwdAction } from "../../../Redux/Action/AuthAction";

import { CircularProgress } from "@material-ui/core";
import Swal from "sweetalert2";
import { Button } from "@material-ui/core";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      error: undefined,
      data: undefined,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.response !== this.props.response) {
      if (this.props.response.message && !this.props.response?.response) {
        this.setState({ error: this.props.response.message, isLoading: false });
        Swal.fire(this.props.response.message, '', 'error');
      }
      if (this.props.response?.response?.data) {
        this.setState({
          data: this.props.response.response.data,
          isLoading: false,
        });
        // Swal.fire('Info', 'Code sent to your email. Pls check your spam folder also', 'success');
        this.props.history.push("/verify-code", {
          data: this.props.response.response.data,
        });
      }
    }
  }

  render() {
    return (
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center mb-3">
          <div className="col">
            <h4 className="text-center">Forgot Password</h4>
            <Formik
              validate={(values) => {
                let errors = {};
                if (this.state.error) {
                  this.setState({ error: '' });
                }
                if (!values.email) {
                  errors.email = "Email is Required";
                } else if (!EmailValidator.validate(values.email)) {
                  errors.email = "Invalid email address.";
                }
                return errors;
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email().required("Required"),
              })}
              initialValues={{ email: "" }}
              onSubmit={(values, { setSubmitting }) => {
                this.props.dispatch(forgotPwdAction(values));
                setSubmitting(false);
                this.setState({ isLoading: true });
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="input-with-icon-placeholder position-relative">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Email ID"
                        className="form-input my-3 fontAwesome"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <i className="fa fa-envelope"></i>
                      {errors.email && touched.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}

                      {this.state.email ? (
                        <div className="text-success m-2">
                          {this.state.error}
                        </div>
                      ) : (
                        <div className="text-danger m-2">
                          {this.state.error}
                        </div>
                      )}
                    </div>
                    <div className="text-center">
                      {this.state.isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          type="submit"
                          className="form-btn"
                        >
                          Get Code
                        </Button>
                      )}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.forgotPassword,
  };
};

export default connect(mapStateToProps, null)(ForgotPassword);
