import * as types from '../Action';

const initState = {
  message: undefined
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initState, action){
  const response = action.payload;

  switch(action.type) {
    case types.USER_PROFILE:
      return { ...state, message:"" };
    case types.USER_PROFILE_SUCCESS:
      return { ...state, response, message:"Your profile updated successfully!" };
    case types.USER_PROFILE_ERROR: 
      return { ...state, message:"Something went wrong please try again!" };
    case types.USER_PROFILE_CLEAR:
      return { ...state, message:"" };
    default:
      return state;
  }
};