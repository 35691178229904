import React from 'react';
import Header from '../UI/Header/Header';
import ToothImg from '../../assets/Dashboard/reports/report-tooth.svg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <>
            <Header title='Page not found' routeRedirection="/dashboard" />
            <div className='margin-top-70px p-5'>
                <img className="w-100" src={ToothImg} alt="tooth img" />
                <h4 className='text-center my-5'>Page Not Found</h4>
                <Button className="form-btn">
                    <Link className='d-flex align-items-center justify-content-center text-white' to="/dashboard">Back To Dashboard</Link>
                </Button>
            </div>
        </>
    )
}

export default PageNotFound;
