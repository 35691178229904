import React from "react";
import { CircularProgress } from "@material-ui/core";

const CameraModuleBottomImage = (props) => {
  return (
    <div className="camera-module-bottom-images" onClick={props.onClick}>
      {props.isImage ? (
        <div className="position-relative">
          <img
            className="width-60px height-60px border-radius-8px"
            aria-label="image"
            src={props.image}
          />
          <span className={`number-and-check-icon font-size-18 font-weight-bold text-center position-absolute text-white ${props?.isReshoot ? "bg-danger" : "bg-primary"}`}>
            <i className={`${props?.isReshoot ? "fas fa-window-close" : "fas fa-check"}`}></i>
          </span>
        </div>
      ) : (
        <div>
          {props.image ? (
            <CircularProgress className="text-white" />
          ) : (
            <div className="position-relative mb-2">
              <i className="fas fa-plus width-60px height-60px grey-bg-color primary-color d-flex align-items-center justify-content-center border-radius-8px font-size-20"></i>
              <span className="number-and-check-icon font-size-18 font-weight-bold text-center position-absolute bg-white grey-dark-color">{props.count}</span>
            </div>

          )}
        </div>
      )}
    </div>
  );
};

export default CameraModuleBottomImage;
