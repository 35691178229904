import React, { useEffect, useState, useRef } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Landing from "./Components/LandingPage/landing";
import Login from "./Components/Login-Signup/Login/login";
import signUp from "./Components/Login-Signup/Signup/signUp";
import signUpStepTwo from "./Components/Login-Signup/Signup/signup-stepTwo/SignupStepTwo";
import Dashboard from "./Components/Dashboard/dashboard";

import Blog from "./Components/Dashboard/Drawer/Blog/Blog";
import AboutUs from "./Components/Dashboard/Drawer/AboutUS/AboutUs";
import ProfileDetails from "./Components/Dashboard/Drawer/ProfileDetails/ProfileDetails";
import InsuranceDetails from "./Components/Dashboard/Drawer/InsuranceDetails/InsuranceDetails";
import ChangePassword from "./Components/Dashboard/Drawer/ChangePassword/ChangePassword";
import Subscription from "./Components/Dashboard/Drawer/Subscription/Subscription";
import NotificationReminders from "./Components/Dashboard/Drawer/NotificationReminder/NotificationRimind";
import Faq from "./Components/Dashboard/Drawer/FAQ/FAQ";
import InstructionForUse from "./Components/Dashboard/Drawer/InstructionForUse/InstructionForUse";
import PrivacyPolicy from "./Components/Dashboard/Drawer/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./Components/Dashboard/Drawer/TermsOfUse/TermsOfUse";

import Cavity from "./Components/Dashboard/Cavity/Cavity";
import TotalTest from "./Components/Dashboard/TotalTest/TotalTest";
import Tartar from "./Components/Dashboard/Tartar/Tartar";
import DentalRiskScore from "./Components/Dashboard/DentalRiskScore/DentalRiskScore";
import Report from "./Components/Dashboard/TotalTest/Report/Report";
import CameraModuleTwo from "./Components/Dashboard/CameraModule/CameraModule2";
import CameraModuleReshot from "./Components/Dashboard/CameraModule/CameraModuleReshot";
import CameraModuleFirst from "./Components/Dashboard/CameraModule/CameraModuleFirst";
import ForgotPassword from "./Components/Login-Signup/ForgotPassword/ForgotPassword";
import VerifyCode from "./Components/Login-Signup/ForgotPassword/VerifyCode";
import NewPassword from "./Components/Login-Signup/ForgotPassword/NewPassword";
import Insights from "./Components/Dashboard/Insights/Insights";
import Questionnaire from "./Components/Dashboard/Questionnaire/Questionnaire";
import VerifyUserCode from "./Components/Login-Signup/Signup/VerifyUserCode";
import ScrollToTop from "./common/scrollToTop";
import QrCodePage from "./Components/QrCodePage/QrCodePage";
import Knowledge from "./Components/Dashboard/Knowledge/Knowledge";
import SplashPage from "./Components/SplashPage/SplashPage";
import SelectBranches from "./Components/Dashboard/TotalTest/Report/SelectBranches";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
// import { startRefreshTokenTimer, refreshToken } from "./Redux/Services/AuthService";
import CameraErrorPopup from "./Components/UI/Dialog/CameraErrorPopup";
import { GetBrowserDetails, GetOSDetails } from "./Components/Utils/Device/deviceDetails";
import ReportHistory from "./page/reportHistory";

function App() {
  const history = useHistory();
  const [isDesktop, setIsDesktop] = useState(false);
  const [isShowDialogPopup, setIsShowDialogPopup] = useState(false);
  const isShowGSAPopup = localStorage.getItem('isShowGSAPopup') === null ? true : false;


  useEffect(() => {

    const browser = GetBrowserDetails();
    const osDetails = GetOSDetails();
    if (osDetails.name === 'iOS' && parseFloat(osDetails.version) < 15.6) { // parseFloat(osDetails.version) < 15.6, browser.name === 'GSA'
      setIsShowDialogPopup(true);
    }

    localStorage.setItem('isExplainerCard', true);
    const isMobileOrDesktop = getDesktopOrMobile();
    if (isMobileOrDesktop === 'Desktop') {
      localStorage.clear();
      setIsDesktop(true);
    }



    // const user = JSON.parse(localStorage.getItem('user'));
    // const authToken = localStorage.getItem('authToken');

    // if (user && authToken) {
    //   startRefreshTokenTimer(authToken, user.token);
    //   const jwtToken = JSON.parse(atob(authToken.split('.')[1]));

    //   // set a timeout to refresh the token a minute before it expires
    //   const expires = new Date(jwtToken.exp * 1000);
    //   const timeout = expires.getTime() - Date.now() - (60 * 1000);
    //   setTimeout(() => {
    //     refreshToken(user.token).then((res) => {
    //       console.log('res', res);
    //     }).catch((error) => {
    //       localStorage.clear();
    //       // history.push('/login');
    //     })
    //   }, timeout);
    // }

    return history.listen((location) => {
      if (history.action === "POP") {
        const routeUrl = location.pathname;
        const splitUrl = routeUrl.split('-')[0];
        if (splitUrl === '/camera') {
          return history.push('/camera-module-two', { isQuickScan: location.state.isQuickScan });
        }
        if (localStorage.getItem('user')) {
          return history.push('/dashboard');
        }
        // window.addEventListener("popstate", () => {
        //   history.go(1);
        // });
      }
      // if (history.action === "PUSH") {
      //   console.log("location PUSH", location);
      // }
    });
  }, []);

  // Checking whether its mobile or Desktop
  const getDesktopOrMobile = () => {
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);
    if (isMobile) {
      return 'Mobile';
    } else {
      return 'Desktop';
    }
  }

  const closePopup = () => {
    setIsShowDialogPopup(false);
  }


  return (
    isDesktop ? (
      <Switch>
        <Route exact path="/" component={QrCodePage} />
        <Route path="*" component={QrCodePage} />
      </Switch>) : (
      <ScrollToTop>
        {
          (isShowDialogPopup && isShowGSAPopup) && <CameraErrorPopup open={isShowDialogPopup} onClose={closePopup} errorMessage="gsaError" />
        }
        <Switch>
          <Route exact path="/cs" component={Landing} />
          <Route exact path="/" component={Landing} />
          <Route path="/m" component={Landing} />
          <Route path="/splash" component={SplashPage} />
          <Route path="/signup" component={signUp} />
          <Route path="/sign-up" component={signUpStepTwo} />
          <Route path="/login" component={Login} />
          <Route path="/camera-module-first" component={CameraModuleFirst} />
          <Route path="/camera-module-two" component={CameraModuleTwo} />
          <Route path="/camera-module-reshot" component={CameraModuleReshot} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/verify-code" component={VerifyCode} />
          <Route path="/verify-user-code" component={VerifyUserCode} />
          <Route path="/new-password" component={NewPassword} />

          {/* Dashboard */}
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/cavity" component={Cavity} />
          <Route path="/tartar" component={Tartar} />
          <Route path="/total-test" component={TotalTest} />
          <Route path="/total-test-report/:id" exact component={Report} />
          <Route path="/select-branches/:reportId" component={SelectBranches} />
          <Route path="/insights" exact component={Insights} />
          <Route path="/knowledge" exact component={Knowledge} />
          <Route path="/report-history/:reportId" exact component={ReportHistory} />
          
          {/* Drawer  */}
          <Route path="/subcription" component={Subscription} />
          <Route path="/profile-details" component={ProfileDetails} />
          {/* <Route path="/dental-risk-score" component={DentalRiskScore} /> */}
          {/* <Route path="/questionnaire" exact component={Questionnaire} /> */}
          {/* <Route path="/insurance-details" component={InsuranceDetails} /> */}
          <Route path="/change-password" component={ChangePassword} />
          <Route
            path="/Notification&Reminders"
            component={NotificationReminders}
          />
          <Route path="/FAQ" component={Faq} />
          <Route path="/instruction-for-use" component={InstructionForUse} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/blog" component={Blog} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/abouts-us" component={AboutUs} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </ScrollToTop>
    )

  );
}

export default App;
