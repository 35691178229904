import { Visibility, VisibilityOff } from "@material-ui/icons"
import { useState } from "react";

const InputPassword = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const { id, placeholder, name, error, value, handleChange, handleBlur, touched } = props;

    const handleClickShowPassword = () => {
        setShowPassword((prevState) => !prevState);
      }

    return (
        <>
            <div className="position-relative">
                <input
                    id={id}
                    name={name}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={placeholder}
                    className="form-input my-3 fontAwesome"
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <span className={`position-absolute show-password`}
                    onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </span>
            </div>
            <i className="fa fa-lock"></i>
            {error && touched && (
                <div className="text-danger">{error}</div>
            )}
        </>
    )
}

export default InputPassword;