import { Button } from "@material-ui/core";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const QuickScanModal = ({ imgCount, isQuickScan, onSubmit }) => {
  return (
    <Row className="mx-0">
      <Col xs={12} className="d-flex justify-content-center mx-auto">
        <Card className="all-well-modal">
          <Card.Body className="p-0 mt-2">
            <Card.Text className="black-color text-center py-3 px-2 font-weight-600">
              Do you want to continue taking photos or submit it?
            </Card.Text>
            <div className="my-3">
              <Button
                className="text-center font-size-14 text-white primary-bg font-weight-bold"
                variant="contained"
                size="small"
                onClick={() => onSubmit(true)}
              >
                Submit and Share with dental office
              </Button>
            </div>
            {
              imgCount <= 3 && (
                <Link to={{
                  pathname: "/camera-module-first",
                  state: { data: '', imgCount, isQuickScan },
                }}>
                  <Button
                    className="text-center font-size-19 text-white primary-bg font-weight-bold"
                    variant="contained"
                    size="small"
                  >
                    Continue
                  </Button>
                </Link>
              )
            }
            {/* <Button
              className="text-right font-size-19 text-white primary-bg font-weight-bold ml-3"
              variant="contained"
              size="small"
              onClick={() => onSubmit(false)}
            >
              Submit
            </Button> */}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default QuickScanModal;