import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Slider } from "@material-ui/core";

import CavityImg from "../../../assets/Dashboard/new-images/cavity.svg";
import TartarImg from "../../../assets/Dashboard/new-images/tartar.svg";
import InsightsImg from "../../../assets/Dashboard/new-images/insights.svg";
import Update from "../../../assets/Dashboard/update.svg"
import DentalRiskImg from "../../../assets/Dashboard/new-images/dental-risk.svg";
import Button from "@material-ui/core/Button";
import HippaLogo from '../../../assets/logo/hipaa.jpg';

import {
  getReportsCount,
  getLatestReport,
  getQuestionnaireById,
  getPopupDate
} from "../../../Redux/Services/AuthService";
import PwaDialog from "./PwaDialog";
import SnackBarMessages from "../../UI/Snackbar/Snackbar";
import ExplainerCard from "../../UI/Card/ExplainerCard/ExplainerCard";
import { CavityAndTartarMarks, DentalRiskScoreMark } from "./SliderMarks";
import SendReportBtnUI from "../TotalTest/Report/SendReportBtnUI";
import ReviewFlagExplainer from "../../UI/Card/ExplainerCard/ReviewFlagExplainer";
import ConsultOnlineDialog from "./ConsultOnlineDialog";
import Popup from "./Popup";
import "../dashboard.css";
import ButtonImg from "../../../assets/Dashboard/new-images/btn-image.svg";
import ScanBtn from "../../UI/Buttons/ScanBtn";
import moment from "moment";
import logo from "../../../assets/logo/logo67x67.svg";

class Dashboard extends Component {
  constructor(props) {
    super();
    this.cavityTartarSliderMarks = CavityAndTartarMarks;
    this.dentalRiskScoreMark = DentalRiskScoreMark;

    this.state = {
      cavity: 0,
      tartar: 0,
      cavitySliderValue: 0,
      tartarSliderValue: 0,
      first_name: "",
      last_name: "",
      reportCount: 0,
      dentalScore: 0,
      dentalScoreStatus: "",
      dentalRiskSliderValue: 0,
      isSnackbar: false,
      isExplainerCard: false,
      isGradeExplainerCard: false,
      reportId: '',
      isShowSendReportExplainer: JSON.parse(localStorage.getItem('isShowSendReportExplainer')) === null ? true : false,
      isQuestionnaireTaken: false,
      isShowConsultPopup: false,
      consultMessage: '',
      popup: 0,
      trigger: false,
      quickScanCheckup: false //JSON.parse(localStorage.getItem("quickScanCheckup"))?.is_quickScan_checkup
    };
  }

  componentDidMount() {
    const userDetails = JSON.parse(localStorage.getItem("user"))?.user;
    // var quickScanCheckup = JSON.parse(localStorage.getItem("quickScanCheckup"))?.is_quickScan_checkup;
    if (userDetails && (!userDetails.phone_number || !userDetails.gender)) {
      return this.props.history.push("/profile-details", { title: 'Update personal data' });
    } else if (userDetails) {
      const reportImages = JSON.parse(localStorage.getItem('report'));
      if (reportImages) {
        this.props.reportUpload(reportImages);
      }
      this.getReportCountServiceHandler();
      getPopupDate()
        .then((res) => {
          if (res) {
            const response = res.data.data.popup_date;
            const date1 = new Date(response);
            const date2 = new Date();
            const diffInMs = date1 - date2;
            const datediff = Math.trunc(diffInMs / (1000 * 60 * 60 * 24));
            if (datediff === 0) {
              this.setState({ trigger: true })
            }
            if (datediff <= -1) {
              this.setState({ trigger: true })
            }

          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.explainerCardHandler();
    }
  }

  componentDidUpdate() {
    // console.log('this.props.reportUploadResponse?.status', this.props.reportUploadResponse?.status)
    if (this.props.reportUploadResponse?.status === 200) {
      localStorage.removeItem('report');
    }
  }

  OnCloseDialog() {
    this.setState({ trigger: false });
  }
  explainerCardHandler() {
    return setTimeout(() => {
      if (localStorage.getItem("isExplainerCard") && !(this.state.trigger)) {
        this.setState({ isExplainerCard: true });
      }
    }, 1000);
  }

  sliderActiveClassHandler(element, cssClass) {
    const dental = document.getElementById(element);
    const cssClasses = dental.querySelectorAll(
      ".MuiSlider-markLabel.MuiSlider-markLabelActive"
    );
    cssClasses[cssClasses.length - 1].classList.add("active-slider");
    cssClasses[cssClasses.length - 1].classList.add(cssClass);
  }

  getReportCountServiceHandler() {
    getReportsCount()
      .then((res) => {
        if (res && res.data.data !== "No Data") {
          this.getLatestReportServiceHandler();
          this.getQuestionnaireDetails();
          localStorage.setItem('reportCount', JSON.stringify(res.data.data));
          this.setState({ reportCount: res.data.data });
          if (localStorage.getItem("isGradeExplainer") && this.state.reportCount === 1) {
            this.setState({ isGradeExplainerCard: true });
          }
        } else {
          this.explainerCardHandler();
        }
      })
      .catch((err) => { });
  }

  getLatestReportServiceHandler() {
    getLatestReport()
      .then((res) => {
        if (res && res.data.data.length > 0) {
          // calculation score of cavity
          const score = res.data.data[0].report_score[0].overall_score[1];
          const reportId = res.data.data[0]._id;
          const userId = res.data.data[0].user_id;
          this.setState({ reportId, userId });
          this.cavityCalculation(score);

          // calculation score of tartar
          const tartarScore = res.data.data[0].report_score[0].overall_score[0];
          this.tartarCalculation(tartarScore);

          // This is for consult popup
          if (localStorage.getItem("isGradeExplainer") && this.state.reportCount === 1) {
            const message = this.getConsultMessage(score, tartarScore);
            if (message) {
              this.setState({ isShowConsultPopup: true, consultMessage: message });
            }
          }

        } else {
          if (localStorage.getItem("isExplainerCard") && this.state.reportCount === 1) {
            this.setState({ isExplainerCard: true });
          }
        }
      })
      .catch((err) => { });
  }

  cavityCalculation(score) {
    if (score === 0) {
      this.setState({ cavitySliderValue: 0 });
      this.markGradePointer('left-side-popover', 3);
      this.sliderActiveClassHandler("cavitySlider", "text-success");
    } else if (score === 1) {
      this.markGradePointer('left-side-popover', 11);
      this.setState({ cavitySliderValue: 75 });
      this.sliderActiveClassHandler("cavitySlider", "text-danger");
    } else if (score > 1) {
      this.markGradePointer('left-side-popover', 20);
      this.setState({ cavitySliderValue: 100 });
      this.sliderActiveClassHandler("cavitySlider", "text-danger");
    }
    this.setState({ cavity: score });
  }

  tartarCalculation(tartarScore) {
    if (tartarScore <= 6) {
      this.markGradePointer('right-side-popover', 38);
      this.sliderActiveClassHandler("tartarSlider", "text-success");
    } else if (tartarScore > 6 && tartarScore <= 12) {
      this.markGradePointer('right-side-popover', 28);
      this.setState({ tartarSliderValue: 25 });
      this.sliderActiveClassHandler("tartarSlider", "text-success");
    } else if (tartarScore > 12 && tartarScore <= 18) {
      this.markGradePointer('right-side-popover', 18);
      this.setState({ tartarSliderValue: 50 });
      this.sliderActiveClassHandler("tartarSlider", "text-warning");
    } else if (tartarScore > 18 && tartarScore <= 24) {
      this.markGradePointer('right-side-popover', 8);
      this.setState({ tartarSliderValue: 75 });
      this.sliderActiveClassHandler("tartarSlider", "text-danger");
    } else if (tartarScore > 24) {
      this.markGradePointer('right-side-popover', -8);
      this.setState({ tartarSliderValue: 100 });
      this.sliderActiveClassHandler("tartarSlider", "text-danger");
    }
    this.setState({ tartar: tartarScore });
  }

  markGradePointer(className, value) {
    if (className === 'left-side-popover') {
      return document.styleSheets[0].insertRule(`.${className}::after { left: ${value}% !important; }`, 0);
    } else {
      return document.styleSheets[0].insertRule(`.${className}::after { right: ${value}% !important; }`, 0);
    }
  }

  getQuestionnaireDetails() {
    let userId = JSON.parse(localStorage.getItem("user"))?.user._id;
    if (userId) {
      getQuestionnaireById(userId)
        .then((res) => {
          if (res && res.data.data.length > 0) {
            this.setState({ isQuestionnaireTaken: true });
            // calculation score of dental score
            const score = res.data.data[0].score;
            if (score < 1.5) {
              this.setState({
                dentalScoreStatus: "Low",
                dentalRiskSliderValue: 0,
              });
              this.sliderActiveClassHandler("dentalSlider", "text-success");
            } else if (score > 1.5 && score < 2.5) {
              this.setState({
                dentalScoreStatus: "Medium",
                dentalRiskSliderValue: 50,
              });
              this.sliderActiveClassHandler("dentalSlider", "text-warning");
            } else if (score > 2.5) {
              this.setState({
                dentalScoreStatus: "High",
                dentalRiskSliderValue: 100,
              });
              this.sliderActiveClassHandler("dentalSlider", "text-danger");
            }
            this.setState({ dentalScore: score });
          }
        })
        .catch((err) => { });
    }
  }

  handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      this.setState({ value: newValue });
    }
  };

  handlePwaPopup = (data) => {
    this.setState({ isSnackbar: data });
  };

  getConsultMessage(cavityScore, tartarScore) {
    if (cavityScore > 0 && tartarScore > 0) {
      return 'Cavity/Tartar detected!'
    } else if (cavityScore > 0) {
      return 'Cavity detected!';
    } else if (tartarScore > 0) {
      return 'Tartar detected!';
    }
    return '';
  }

  createFolder() {
    if (!localStorage.getItem("reportFolderName")) {
      const reportFolderName = Math.floor(100000 + Math.random() * 900000)
      console.log(reportFolderName)
      localStorage.setItem("reportFolderName", JSON.stringify({ reportFolderName }));
    }
    localStorage.setItem("isQuickScan", false);
  }


  render() {
    const userDetails = JSON.parse(localStorage.getItem("user"));

    return (
      <Container fluid className="mt-5 pt-3 max-width-450px">
        <Row>
          <Col xs={12} className="d-flex my-4">
            {userDetails
              ? <Row>
                <Col xs={9}>
                  <div className="font-size-20 mx-3 ">{userDetails.user.first_name} {userDetails.user.last_name}
                  </div>



                  <div className=" mx-3 ">{userDetails.user.gender}</div>
                  <div className=" mx-3 ">DOB:{moment(userDetails.user.dob).format('MM-DD-YYYY')}</div>
                  <div className=" mx-3 ">{userDetails.user.city}, {userDetails.user.state}, {userDetails.user.zip_postal_code}.</div>
                </Col>
                <Col xs={2} className=" d-flex justify-content-end align-items-center">
                  <Link to="/profile-details"><Button className="animate-btn"><img className={`w-20px h-10px`} src={Update} alt="Update img" /></Button> </Link>




                </Col>
                {/* <div></div>
                <div></div> */}

              </Row>
              : <div>"Hi Guest!!!"</div>}


            <div className="text-right flex-1 position-relative">
              {/* {
                this.state.reportCount > 0 && (
                  <SendReportBtnUI path={!this.state.isQuestionnaireTaken ? '/questionnaire' : ''}
                      reportId={this.state.reportId} userId={this.state.userId} />
                )
              } */}
              {/* {
                (this.state.isShowSendReportExplainer && this.state.reportCount === 1) && (
                  <ReviewFlagExplainer
                    explainerCardName="send report"
                    cssClass="send-report-explainer-card"
                    message="Click to connect with doctor"
                    isShowCancelIcon={true}
                    />
                )
              } */}
            </div>
          </Col>
        </Row>




        {/* Dental score */}
        {/* <Col
          xs={12} sm={12}
          id="dentalSlider"
          className="my-3 d-flex justify-content-center dental-risk-slider"
        >
          <Link to="/dental-risk-score">
            <div className="dashboard-box p-3 primary-bg dashboard-box-width-100">
              <div className="d-flex my-1 font-size-19 text-white font-weight-500">
                <div>Dental Risk</div>
                <div className="flex-grow-1 text-right font-size-12 pt-1">
                  <i className="fas fa-chevron-right pt-1"></i>
                </div>
              </div>
              <div className="my-2">
                <img src={DentalRiskImg} alt="dental-risk-img" height="28" />
              </div>
              <Slider
                aria-label="Restricted values"
                key={`slider-${this.state.dentalRiskSliderValue}`}
                defaultValue={this.state.dentalRiskSliderValue}
                step={null}
                valueLabelDisplay="auto"
                marks={this.dentalRiskScoreMark}
                disabled
              />
            </div>
          </Link>
          {this.state.isExplainerCard && (
            <ExplainerCard
              index={2}
              isGrade={false}
              content="dental"
              btnText="Next"
              cssClass="left-side-popover"
            />
          )}
        </Col> */}

        {/* Insights */}
        <Col xs={12} sm={12} className="mt-3 d-flex justify-content-center">
          <Link to="/total-test">
            <div className="dashboard-box p-3 dashboard-box-width-100">
              <div className="d-flex my-2 font-size-19 black-color font-weight-500">
                <div>Previous Report(s)</div>
                <div className="flex-grow-1 text-right font-size-12 mt-2">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </div>
              <div className="my-2">
                <img src={InsightsImg} alt="tartar" />
              </div>
              <div className="d-flex align-items-end flex-column mt-3">
                <div className="font-size-20 font-weight-bold black-color">
                  {this.state.reportCount}
                </div>
                <div className="black-color">
                  <small>Report(s)
                    {/* {this.state.reportCount > 1 ? 's' : ''} */}
                  </small>
                </div>
              </div>
            </div>
          </Link>
          {/* {this.state.isExplainerCard && (
              <ExplainerCard
                index={3}
                isGrade={false}
                content="insights"
                btnText="Got it"
                cssClass="right-side-popover"
              />
            )} */}
        </Col>




        {/* click to online consult button */}
        {/* <Col
            xs={12}
            className="px-5 mt-2"
          >
            <Button
              type="submit"
              className="form-btn text-capitalize"
            >

              <a className="d-flex align-items-center justify-content-center text-white font-size-16"
                href="https://teledentists.vsee.me/u/toothlens" target="_blank">Live video consultation</a>
            </Button>
          </Col> */}

        {/* click to scan button */}

        <Row className="mt-1">


          {
            this.state.quickScanCheckup && <Col
              xs={12}
              className="p-0 d-flex flex-column align-items-end justify-content-end"
            >
              <ScanBtn
                name="Click here for Quick Scan"
                path="camera-module-two"
                isQuickScan={true}
              // bottomText="1-2 photos"
              />
            </Col>
          }


          {!this.state.quickScanCheckup && <Col
            xs={12}
            className="p-0 d-flex flex-column align-items-center justify-content-center"
          >
            <Link className="w-100 text-center" to={"instruction-for-use"}>
              <button className="animate-btn" onClick={this.createFolder}>
                <img className={`w-240px my-3 pl-4`} src={ButtonImg} alt="button img" /> <br />
                <span className="black-color position-relative top--48px">Please Take Photos to Create a Report</span>
              </button>
            </Link>
            {/* <ScanBtn
              name="Click here for Complete Scan"
              path={this.state.reportCount >= 2 ? "camera-module-two" : "instruction-for-use"}
              isQuickScan={false}
            // bottomText="5 photos"
            /> */}
          </Col>}

        </Row>

        <PwaDialog handlePwaPopup={this.handlePwaPopup.bind(this)} />
        {
          this.state.consultMessage && (
            <ConsultOnlineDialog isOpenDialog={this.state.consultMessage ? true : false} message={this.state.consultMessage}
              reportId={this.state.reportId} />
          )
        }



        {/* snackbar */}
        {this.state.isSnackbar && (
          <SnackBarMessages
            open={this.state.isSnackbar}
            message="App installed successfully "
            timing={5000}
            type="success"
          />
        )}
        {this.state.trigger && <Popup open={this.state.trigger} onClose={this.OnCloseDialog.bind(this)} />}



        <div className=' mx-auto d-flex w-5 align-items-center justify-content-center my-3'>

          <div className='font-weight-600'>Powered by Toothlens</div>
          <div className="mx-3">
            <img src={logo} alt='hippa logo' width="25" />
          </div></div>

      </Container>
    );
  }
}

const mapDespatchToProps = (dispatch) => {
  return {
    reportUpload: (reportUpload) => {
      dispatch({ type: "USER_REPORT_UPLOAD", reportUpload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loginResponse: state.login.response,
    reportUploadResponse: state.reportUpload.response
  };
};
// export default connect(mapStateToProps, null)(Dashboard);
export default withRouter(connect(mapStateToProps, mapDespatchToProps)(Dashboard));
