import * as UAParser from 'ua-parser-js';

const parser = new UAParser().getResult();

export const GetBrowserDetails = () => {
    return parser.browser;
};

export const GetDeviceDetails = () => {
    return parser.device;
};

export const GetOSDetails = () => {
    return parser.os;
}

export const GetFullDetails = () => {
    return parser;
}