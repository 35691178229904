import PregnantOtion1 from './../../../assets/Questionnaire/pregnant-option-1.svg';
import PregnantOtion2 from './../../../assets/Questionnaire/pregnant-option-2.svg';

import DiabetesOtion1 from './../../../assets/Questionnaire/diabetes-option-1.svg';
import DiabetesOtion2 from './../../../assets/Questionnaire/diabetes-option-2.svg';
import DiabetesOtion3 from './../../../assets/Questionnaire/diabetes-option-3.svg';

import FamilyhistoryOtion1 from './../../../assets/Questionnaire/family-history-option-1.svg';
import FamilyhistoryOtion2 from './../../../assets/Questionnaire/family-history-option-2.svg';

import Option0 from './../../../assets/Questionnaire/option-zero.svg';
import Option1 from './../../../assets/Questionnaire/option-one.svg';
import Option2 from './../../../assets/Questionnaire/option-two.svg';

import ToothbrushOtion1 from './../../../assets/Questionnaire/toothbrush-option-1.svg';
import ToothbrushOtion2 from './../../../assets/Questionnaire/toothbrush-option-2.svg';
import ToothbrushOtion3 from './../../../assets/Questionnaire/toothbrush-option-3.svg';

import SmokingOtion1 from './../../../assets/Questionnaire/smoking-option-1.svg';
import SmokingOtion2 from './../../../assets/Questionnaire/smoking-option-2.svg';

import CavityTreatmentOtion1 from './../../../assets/Questionnaire/cavity-treatment-option-1.svg';
import CavityTreatmentOtion2 from './../../../assets/Questionnaire/cavity-treatment-option-2.svg';
import CavityTreatmentOtion3 from './../../../assets/Questionnaire/cavity-treatment-option-3.svg';

import DentalScalingOtion1 from './../../../assets/Questionnaire/dental-scaling-option-1.svg';
import DentalScalingOtion2 from './../../../assets/Questionnaire/dental-scaling-option-2.svg';
import DentalScalingOtion3 from './../../../assets/Questionnaire/dental-scaling-option-3.svg';

import CoffeeOption from './../../../assets/Questionnaire/coffee-option.svg';

import ToothProblemOtion1 from './../../../assets/Questionnaire/tooth-problem-option-1.svg';
import ToothProblemOtion2 from './../../../assets/Questionnaire/tooth-problem-option-2.svg';
import ToothProblemOtion3 from './../../../assets/Questionnaire/tooth-problem-option-3.svg';
import ToothProblemOtion4 from './../../../assets/Questionnaire/tooth-problem-option-4.svg';
import ToothProblemOtion5 from './../../../assets/Questionnaire/tooth-problem-option-5.svg';

export const AllQuestions = [
    {
        question: "Are you pregnant?",
        options: [
            { option: "No", value: 1, icon: PregnantOtion1 },
            { option: "Yes", value: 2, icon: PregnantOtion2 },
        ],
        multiple: false,
    },
    {
        question: "Do you have Diabetes?",
        options: [
            { option: "No", value: 1, icon: DiabetesOtion1 },
            { option: "Yes(good control)", value: 2, icon: DiabetesOtion2 },
            { option: "Yes(poor control)", value: 3, icon: DiabetesOtion3 },
        ],
        multiple: false,
    },
    {
        question: "Family history of cavities and gum infections",
        options: [
            { option: "No", value: 1, icon: FamilyhistoryOtion1 },
            { option: "Yes", value: 2, icon: FamilyhistoryOtion2 },
        ],
        multiple: false,
    },
    {
        question: "Toothbrushing",
        options: [
            { option: "Twice daily", value: 1, icon: Option1 },
            { option: "Once daily", value: 3, icon: Option2 },
        ],
        multiple: false,
    },
    {
        question: "Flossing",
        options: [
            { option: "Twice daily", value: 1, icon: Option1 },
            { option: "Once daily", value: 2, icon: Option2 },
            { option: "None", value: 3, icon: Option0 },
        ],
        multiple: false,
    },
    {
        question: "How often do you replace toothbrush",
        options: [
            { option: "less than 3 months", value: 1, icon: ToothbrushOtion1 },
            { option: "3-6 months", value: 2, icon: ToothbrushOtion2 },
            { option: "more than 6 months", value: 3, icon: ToothbrushOtion3 },
        ],
        multiple: false,
    },
    {
        question: "Smoking, tobacco and vaping use",
        options: [
            { option: "No", value: 1, icon: SmokingOtion1 },
            { option: "Yes", value: 3, icon: SmokingOtion2 },
        ],
        multiple: false,
    },
    {
        question: "Cavities or treatment for cavities in last 36 months",
        options: [
            { option: "None", value: 1, icon: CavityTreatmentOtion1 },
            { option: "less than 3 cavities", value: 2, icon: CavityTreatmentOtion2 },
            { option: "more than 3 cavities", value: 3, icon: CavityTreatmentOtion3 },
        ],
        multiple: false,
    },
    {
        question: "Dental Scaling/Gum treatment done",
        options: [
            { option: "more than a year", value: 3, icon: DentalScalingOtion1 },
            { option: "within one year", value: 1, icon: DentalScalingOtion2 },
            { option: "NA", value: 2, icon: DentalScalingOtion3 },
        ],
        multiple: false,
    },
    {
        question: "How many cups of coffee/tea do you drink in a day?",
        options: [
            { option: "Upto 3 cups", value: "Upto 3 cups", icon: CoffeeOption },
            { option: "More than 3 cups", value: "More than 3 cups", icon: CoffeeOption },
            { option: "NA", value: "NA", icon: DentalScalingOtion3 },

        ],
        multiple: false,
    },
    // {
    //   question: "Do you have any of these problems now?",
    //   options: [
    //     { option: "No problem", value: "No problem", icon: ToothProblemOtion1 },
    //     { option: "Tooth pain", value: "Tooth pain", icon: ToothProblemOtion2 },
    //     { option: "Gum bleeding", value: "Gum bleeding", icon: ToothProblemOtion3 },
    //     {
    //       option: "Food getting stuck in teeth",
    //       value: "Food getting stuck in teeth",
    //       icon: ToothProblemOtion4
    //     },
    //     { option: "Poor tooth alignment", value: "Poor tooth alignment", icon: ToothProblemOtion5 },
    //   ],
    //   multiple: true,
    // },
];