import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { postQuestionnaireData } from "../../../Redux/Services/AuthService";
import Swal from "sweetalert2";
import Header from "../../UI/Header/Header";
import "./Questionnaire.css";
import { AllQuestions } from "./QuestionsData";
import { CircularProgress } from "@material-ui/core";


const Questionnaire = (props) => {
  let history = useHistory();
  const [popup, setpopup] = useState(false);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [questions, setQuestions] = useState([]);
  const [popupdate, setpopupdate] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [userQuestionsWithAnswer, setUserQuestionsWithAnswer] = useState(
    props.location.state.userQuestionsData
  );
  const isGeneratePdf = props.location.state.isGeneratePdf;
  const reportId = props.location.state.reportId;

  const handleLoginNavigation = () => {
    history.push("/dashboard", {
      comeBack: props.location.state.comeBack !== undefined ? true : undefined,
    });
  };

  useEffect(() => {
    const allQuestionsWithOption = [...AllQuestions];
    if (userDetails && userDetails.user.gender !== "female") {
      allQuestionsWithOption.splice(0, 1);
    }
    setQuestions(allQuestionsWithOption);
  }, [userDetails, userQuestionsWithAnswer]);

  const questionsInitialValues = () => {
    let initialValues = {};
    questions.forEach((ques, index) => {
      if (userQuestionsWithAnswer[index]?.score) {
        initialValues[`question${index + 1}`] = userQuestionsWithAnswer[index]
          .score
          ? userQuestionsWithAnswer[index].score
          : "";
      } else if (userQuestionsWithAnswer[index]?.answer) {
        initialValues[`question${index + 1}`] = userQuestionsWithAnswer[index]
          .answer
          ? userQuestionsWithAnswer[index].answer
          : "";
      }
    });
    return initialValues;
  };

  return (
    questions.length > 0 && (
      <Container fluid className="dental-risk-bg">
        <Header
          title="Questionnaire"
          routeRedirection="/dental-risk-score"
        />
        <Formik
          validate={(values) => {
            let errors = {};

            for (let index = 0; index < questions.length; index++) {
              if (!values[`question${index + 1}`]) {
                errors[`question${index + 1}`] = "Question is Required";
              }
            }
            return errors;
          }}
          validationSchema={Yup.object().shape(() => {
            let questionsList = {};
            questions.forEach((ques, index) => {
              if (ques.multiple) {
                questionsList[`question${index + 1}`] =
                  Yup.array().required(`question${index + 1} is required`);
              } else {
                questionsList[`question${index + 1}`] =
                  Yup.string().required(`question${index + 1} is required`);
              }
            });
            return questionsList;
          })}
          initialValues={questionsInitialValues()}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              if (userDetails) {
                setIsLoading(true);
                const questionsData = [...questions];
                let score = 0;
                const divideCount =
                  userDetails.user.gender === "female" ? 9 : 8;
                questionsData.forEach((ques, index) => {
                  if (index + 1 <= divideCount) {
                    ques.score = values[`question${index + 1}`];
                    score = score + Number(values[`question${index + 1}`]);
                  } else {
                    ques.answer = values[`question${index + 1}`];
                  }
                });
                const questionWithAnswers = {
                  questions: questionsData,
                  score: score / divideCount,
                  user: userDetails.user._id,
                };
                postQuestionnaireData(
                  questionWithAnswers,
                  isGeneratePdf,
                  reportId
                )
                  .then((res) => {
                    setIsLoading(false);
                    if (res) {
                      Swal.fire(
                        "Congratulations!",
                        "You have successfully taken all the questions",
                        "success"
                      );
                      history.push("/dashboard");
                    }
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    Swal.fire(
                      "Server error, Please try later!",
                      "",
                      "error"
                    );
                  });
              } else {
                Swal.fire("Please login/sign up to continue", "", "info");
                history.push("/");
              }
            }, 100);
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <div>
                <form
                  onSubmit={handleSubmit}
                  className="pt-2 margin-top-70px"
                >
                  {questions.map((ques, index) => {
                    return (
                      <div
                        className={`mt-2 my-3 ${questions.length !== index + 1
                          ? "border-bottom"
                          : ""
                          }`}
                        key={`question${index + 1}`}
                      >
                        <p className="black-color font-size-16">{`${index + 1
                          }. ${ques.question}`}</p>
                        <FormControl className="mb-3" component="fieldset">
                          {ques.multiple === true ? (
                            ques.options.map((option, index2) => {
                              return (
                                <label
                                  key={`option${index2}`}
                                  className="w-100 questions-option d-flex align-items-center justify-content-center"
                                >
                                  <FormControlLabel
                                    className="profile-radios black-color"
                                    control={
                                      <Checkbox
                                        onChange={handleChange}
                                        name={`question${index + 1}`}
                                        color="primary"
                                        value={option.value}
                                      />
                                    }
                                    label={option.option}
                                  />
                                  <div>
                                    <img src={option.icon} alt="icon" />
                                  </div>
                                </label>
                              );
                            })
                          ) : (
                            <RadioGroup
                              row
                              aria-label={`question${index + 1}`}
                              name={`question${index + 1}`}
                              value={values[`question${index + 1}`] || ""}
                              onChange={handleChange}
                            >
                              {ques.options.map((data, index) => {
                                return (
                                  <label
                                    key={`option${index}`}
                                    className="w-100 questions-option d-flex align-items-center justify-content-center"
                                  >
                                    <FormControlLabel
                                      className="profile-radios"
                                      value={`${data.value}`}
                                      control={<Radio color="primary" />}
                                      label={data.option}
                                    />
                                    <div>
                                      <img src={data.icon} alt="icon" />
                                    </div>
                                  </label>
                                );
                              })}
                            </RadioGroup>
                          )}
                        </FormControl>
                        {errors[`question${index + 1}`] &&
                          touched[`question${index + 1}`] && (
                            <div className="text-danger">
                              {errors[`question${index + 1}`]}
                            </div>
                          )}
                      </div>
                    );
                  })}

                  {
                    isLoading ? (
                      <div className="text-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        // disabled={isSubmitting}
                        className="form-btn my-3"
                      >
                        Submit
                      </Button>
                    )
                  }
                </form>
              </div>
            );
          }}

        </Formik>
      </Container>
    )
  );



};

export default connect(null, null)(Questionnaire);
