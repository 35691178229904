import React, { useEffect, useState } from "react";
import { Row, Container, Col, Tabs, Tab } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import Header from "../Components/UI/Header/Header";
import { getReportById } from "../Redux/Services/AuthService";
import SliderCavityTartarCard from "../Components/UI/Slider/SliderCavityTartarCard";
import { REPORT_IMG_URL, TARTAR_IMG_URL } from "../Redux/Services/BaseUrl";
import { getBinaryDataOfImage } from "../Redux/Services/AuthService"
import CavityTartarSkeleton from "../Components/UI/skeleton/cavityTartarSkeleton";
import NoteText from "../Components/UI/Note/NoteText";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";
import "./reportHistory.css";
import { reportPdfDownload } from "../Redux/Services/ReportService";
const ReportHistory = (props) => {
  const [reportImages, setReportImages] = useState([]);
  const [sliderImages, setSliderImages] = useState([])
  const [binaryData, setBinaryData] = useState([])
  const [reportId, setReportId] = useState("");
  const [userId, setUserId] = useState("");
  const [quickScanCheckup, setQuickScanCheckup] = useState();
  const [download, setDownload] = useState();

  useEffect(() => {
    if (reportImages.length > 0) {
      reportImages.forEach(async (obj, index) => {
        const { subFolder, image } = obj
        await getBinaryDataOfImage({ subFolder, image }).then((res) => {
          if (res.status === 200) {
            const imgOb = res.data.data 
            setBinaryData(prevData => [...prevData, imgOb])
          }
        }).catch((err) => {
          const imgeObj = { imageName: `${subFolder}/${image}` }
          setBinaryData(prevData => [...prevData, imgeObj])

        })
      })
    }
  }, [reportImages])


  useEffect(() => {
    if (reportImages.length === binaryData.length) {
      const orderedImages = reportImages.map((obj) => {

        let selected = binaryData.find(binaryObject => obj.image == binaryObject.imageName.split("/")[1])
        if (selected) {
          const byteArray = new Uint8Array(selected.binaryData.data); // Convert the array of integers to a byte array
          const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Assuming it's a JPEG image
          const imageUrl = URL.createObjectURL(blob);
          return { name: obj.imageName, imageUrl }
        }
        return { name: obj.imageName }
      })
      setSliderImages(orderedImages)
    }

  }, [binaryData])


  useEffect(() => {
    getReportById(props.match.params.reportId)
      .then((res) => {
        const resObj = res.data.data[0];
        setReportId(resObj._id);
        setUserId(resObj.user_id);
        const pdf = resObj.fullUrl ? resObj.fullUrl : resObj.pdf_url
        setDownload(pdf);
        let teethList = ["front_teeth", "upper_jaw", "lower_jaw", "right_side", "left_side",];
        const reportImages = []

        teethList.forEach((imageName, i) => {
          if (resObj[imageName] !== '') {
            reportImages.push({ imageName, subFolder: resObj[imageName].split("/")[1], image: resObj[imageName].split("/")[2] })
          }
        })
        setReportImages(reportImages);

      })
      .catch((err) => { });
  }, []);

  const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // centerMode: true,
    cssEase: "linear",
  };
const pdfDownload=async()=>{
  await reportPdfDownload(download).then(res=>{
      const reader = new FileReader();
      // Convert the array of numbers to Uint8Array
      const uint8Array = new Uint8Array(res.data.data.report.data);

      // Create a Blob object from the Uint8Array
      const blob = new Blob([uint8Array], { type: 'application/pdf' });

      // Create a URL for the Blob object
      const url = URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.download = download.split("/")[download.split("/").length- 1];

      // Append the link to the DOM
      document.body.appendChild(link);

      // Trigger a click event on the link to initiate the download
      link.click();

      // Cleanup: remove the temporary link and revoke the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    
  }).catch(err=>console.log(err))
}

  return (
    <Container fluid className="dental-risk-bg min-vh-100">
      <Header title="Report History" routeRedirection="/total-test" />
      {
        reportImages.length > 0 && reportImages.length == sliderImages.length ?
          <>
            <Row>
              <Col className="px-0 overflow">
                <div className="mt-4 pt-5">

                  <div className="shadow bg-white mx-3 my-3 pb-3 border-radius-15px">
                    <Slider {...settings}>
                      {sliderImages.map((item, index) => {
                        return (
                          <div key={item.name} className="cavity-slider">
                            <div className="mb-2 text-capitalize">
                              <b>
                                {quickScanCheckup
                                  ? `Photo${index + 1}`
                                  : item.name.split("_").join(" ")}
                              </b>
                            </div>
                            <Card className="cavity-card w-100">
                              {item.imageUrl ? <Card.Img variant="top" src={item.imageUrl} alt="report images" /> : <div className="not-found-image text-center d-flex justify-content-center align-items-center" > Image Not Found</div>}

                            </Card>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  {download && (
                    <div className="d-flex w-5 justify-content-center align-items-center mt-2">
                      <Button className="bg-none" onClick={pdfDownload}  >
                          <Row>
                            <GetAppIcon
                              width="1.5em"
                              color="blue"
                              height="50px"
                              className="mx-2 background"
                            />
                            <div className="text-dark"><b>Download PDF Report</b></div>
                          </Row>
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </>
          : <CavityTartarSkeleton />
      }
    </Container>
  );
};

export default ReportHistory;
