
// export const API_HOST = "https://app.toothlens.com/api/v1/";
// export const API_HOST = "https://dev.toothlens.com/api/v1/";
// export const API_HOST = "https://testinghygieneuser.toothlens.com/api/v1/";
// export const API_HOST = "http://localhost:3001/api/v1/";
// export const API_HOST = "https://theteledentists.toothlens.com/api/v1/";
// export const API_HOST = "https://dev.toothlens.com/dev-teledentists/api/v1/";
export const API_HOST = "https://demodental.toothlens.com/api/v1/";
export const CAVITY_IMG_URL =
    "https://toothlens-image-store.s3.ca-central-1.amazonaws.com/output/cavity/";
export const TARTAR_IMG_URL =
    "https://toothlens-image-store.s3.ca-central-1.amazonaws.com/output/tartar/";

export const REPORT_IMG_URL =
    "https://toothlens-image-store.s3.ca-central-1.amazonaws.com/input/";