import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import ToothAnimation from "./../../../assets/cameraModule/tooth-animation.gif";

const ScanningTooth = (props) => {

  const [scanningText, setScanningText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setScanningText(true);
    }, 7000);
  }, [])

  return (
    <Row className="mx-0">
      <Col xs={12} className="d-flex justify-content-around mx-auto px-0">
        <div className="all-well-modal p-3">
          <img className="w-100" src={ToothAnimation} alt="teeth-animation" />
          {scanningText && <p className="font-weight-bold my-2">We're working as fast as we can! Hang tight</p>}
        </div>
      </Col>
    </Row>
  );
};

export default ScanningTooth;
