import React from 'react';
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { Card } from 'react-bootstrap';

const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
    // centerMode: true,
    // cssEase: "linear",
};

const QuickScanDemoImages = ({ stepsData }) => {
    return (
        <>
            <Slider {...settings}>
                {stepsData.map((item, index) => {
                    return (
                        <div key={item} className="cavity-slider">
                            <Card className="cavity-card">
                                <Card.Img
                                    variant="top"
                                    src={item.image}
                                    alt="report images"
                                />
                            </Card>
                        </div>
                    );
                })}
            </Slider>
            <p className='font-size-14 font-weight-bold mt-3 mb-5 text-white'>Please take a photo based on the above reference</p>
        </>
    )
}

export default QuickScanDemoImages;