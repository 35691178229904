import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import "./Popup.css";
import Header from "../../UI/Header/Header";
import {
  updatePopupDate,
  postPopUpData,
} from "../../../Redux/Services/AuthService";
import Swal from "sweetalert2";

const Popup = (props) => {
  const { onClose, selectedValue, open } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(open);


  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [option, setoption] = useState({
    id1: false,
    id2: false,
    id3: false,
    id4: false,
    id5: false,
    id6: false,
    id7: false,
    text: "",
    checkbox: [],
  });
  const onchangeid = (e) => {
    const array = option.checkbox;
    if (array.includes(e.target.value)) {
      const index = array.indexOf(e.target.value);
      array.splice(index, 1);
      setoption({ ...option, checkbox: array });
    } else {
      array.push(e.target.value);
      setoption({ ...option, checkbox: array });
    }
  };
  const onchangetext = (e) => {
    e.preventDefault();
    setoption({ ...option, text: e.target.value });
  };
  function handleData(e) {
    const array = option.checkbox;
    array.push(option.text);

    setoption({ ...option, checkbox: array });
    if (option.checkbox.length <= 1 && !option.text) {
      e.preventDefault();
      setoption({ ...option, checkbox: [] });
      Swal.fire("Please fill atleast one field!", "", "error");
    } else {
      postPopUpData(option.checkbox)
      e.preventDefault();
      updatePopupDate();
      onClose(selectedValue);
      setIsOpenDialog(false);
    }
  }

  return props.open ? (
    <div className="model px-3">
      <form onSubmit={handleData}>
        <div className="model_container px-3">
          <div className="title">What are you looking for?</div>
          <div className="content px-3">
            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id1"
                  id="id1"
                  value={"I just need a routine check"}
                />{" "}
              </Col>
              <Col
                className="d-flex justify-content-start align-items-center"
                xs={9}
              >
                <label htmlFor="id1" className="mb-0">I just need a routine check</label>
              </Col>
            </Row>

            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id2"
                  id="id2"
                  value={"I think I have cavities"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                <label htmlFor="id2">I think I have cavities</label>
              </Col>
            </Row>
            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id3"
                  id="id3"
                  value={"I think I have gum problems"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                <label htmlFor="id3">I think I have gum problems</label>
              </Col>
            </Row>
            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  onChange={onchangeid}
                  color="primary"
                  name="id4"
                  id="id4"
                  value={"I have some tooth discomfort"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                {" "}
                <label htmlFor="id4">I have some tooth discomfort</label>
              </Col>
            </Row>

            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id5"
                  id="id5"
                  value={"I am looking for tooth replacement/Bridges/Implants"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                {" "}
                <label htmlFor="id5">I am looking for tooth replacement/Bridges/Implants</label>
              </Col>
            </Row>
            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id6"
                  id="id6"
                  value={"I want a smile correction/braces/aligners"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                <label htmlFor="id6">I want a smile correction/braces/aligners</label>
              </Col>
            </Row>
            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id7"
                  id="id7"
                  value={"I want whiter teeth"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                <label htmlFor="id7">I want whiter teeth</label>
              </Col>
            </Row>
            <Row className="p-3">
              <Col
                xs={4}
                className="d-flex justify-content-center align-items-center"
              >
                Others
              </Col>
              <Col xs={6}>
                <TextField
                  style={{ width: 150 }}
                  label="Query"
                  name="text"
                  margin="normal"
                  type="text"
                  onChange={onchangetext}
                  value={option.text}
                />
              </Col>
            </Row>
          </div>
          <div className="footer">
            <Button className="form-btn" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  ) : (
    ""
  );
};

export default Popup;
