import React from "react";
import { Row, Col } from "react-bootstrap";
import SuccessTeeth from "./../../../assets/cameraModule/Success.svg";

const LoginModal = (props) => {
  return (
    <Row className="mx-0">
      <Col xs={12} className="d-flex justify-content-around mx-auto px-0">
        <div className="all-well-modal p-3">
          <div className="text-center">
            <img src={SuccessTeeth} alt="SuccessTeeth" />
          </div>
          <div className="text-center">
            <h3 className="login-createOne my-3 mx-4 font-size-16">
              <span>Kindly signup to submit photos and get your report.</span>
            </h3>
          </div>
          <div className="text-center mb-3">
            <button
              onClick={props.signup}
              className="modal-signup-btn mt-2"
            >
              Signup
            </button>
          </div>

          <div>
            <h4 className="login-createOne my-3 text-center">
              <span>Already Registered? </span>
              <button
                className="login-link-text"
                onClick={props.login}
              >
                Sign-in
              </button>
            </h4>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default LoginModal;
