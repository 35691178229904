import FrontImg from "./../../../assets/cameraModule/Front.svg";
import UpperImg from "./../../../assets/cameraModule/upper.svg";
import LowerImg from "./../../../assets/cameraModule/Lower.png";
import LeftSideImg from "./../../../assets/cameraModule/Left.svg";
import RightSideImg from "./../../../assets/cameraModule/Right.svg";

import Overlay1 from "./../../../assets/overlay/overlay-1.svg";
import Overlay2 from "./../../../assets/overlay/overlay-2.svg";
import Overlay3 from "./../../../assets/overlay/overlay-3.svg";
import Overlay4 from "./../../../assets/overlay/overlay-4.svg";
import Overlay5 from "./../../../assets/overlay/overlay-5.svg";

export const CameraStepsData = [
  {
    number: 1,
    title: "Front Teeth",
    image: FrontImg,
    headerText: "Please try and align your teeth with the template. Don't worry if it doesn't fit exactly.",
    headerMirrorText: "",
    bottomMirrorText: "Your mobile screen should face a mirror",
    overlay: Overlay1,
  },
  {
    number: 2,
    title: "Upper Jaw",
    image: UpperImg,
    headerText: "Align your teeth with the template",
    headerMirrorText: "Open your mouth tilt your head backwards",
    bottomMirrorText: "Your mobile screen should face a mirror",
    overlay: Overlay2,
  },
  {
    number: 3,
    title: "Lower Jaw",
    image: LowerImg,
    headerText: "Align your teeth with the template",
    headerMirrorText: "Open your mouth and tilt your head downwards",
    bottomMirrorText: "Your mobile screen should face a mirror",
    overlay: Overlay3,
  },
  {
    number: 4,
    title: "Left Side",
    image: LeftSideImg,
    headerText: "Align your teeth with the template",
    headerMirrorText: "Pull the corner of your mouth as back as possible",
    bottomMirrorText: "Your mobile screen should face a mirror",
    overlay: Overlay4,
  },
  {
    number: 5,
    title: "Right Side",
    image: RightSideImg,
    headerText: "Align your teeth with the template",
    headerMirrorText: "Pull the corner of your mouth as back as possible",
    bottomMirrorText: "Your mobile screen should face a mirror",
    overlay: Overlay5,
  },
];
