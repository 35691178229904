import React from 'react';

import CameraBackgroundImg from "../../../assets/cameraModule/camera-bg.jpg";

const CameraModuleBackground = () => {
    return (
        <div className="position-absolute top-0 bottom-0 right-0 left-0 w-100 h-100">
          <div className="position-absolute w-100 h-100 camera-image-bg"></div>
          <div className="w-100 h-100 bg-dark">
            {/* <img
              className="h-100"
              style={{ minWidth: "100%" }}
              src={CameraBackgroundImg}
              alt="camera bg"
            /> */}
          </div>
        </div>
    )
}

export default CameraModuleBackground;