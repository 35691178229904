import React, { useState } from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import FrontSide from './../../../assets/video/sub-videos/Front-Side.gif';
import LeftSide from './../../../assets/video/sub-videos/Left-Side.gif';
import LowerSide from './../../../assets/video/sub-videos/Lower-Jaw.gif';
import RightSide from './../../../assets/video/sub-videos/Right-Side.gif';
import UpperSide from './../../../assets/video/sub-videos/Upper-Jaw.gif';

const videoTutorialData = [
    {
        title: 'Front Side',
        video: FrontSide
    },
    {
        title: 'Upper Jaw',
        video: UpperSide
    },
    {
        title: 'Lower Jaw',
        video: LowerSide
    },
    {
        title: 'Left Side',
        video: LeftSide
    },
    {
        title: 'Right Side',
        video: RightSide
    },
    
]

const TutorialVideoModal = (props) => {
    const { index, onClose, selectedValue, open } = props;
    const [isOpenDialog, setIsOpenDialog] = useState(open);

    const handleClose = () => {
        onClose(selectedValue);
        setIsOpenDialog(false);
    };

    return (
        <Dialog onClose={handleClose} open={isOpenDialog} className="rating-container">
            <div className="p-4 mb-3">
                <h2 className="text-center font-size-20 primary-color">{videoTutorialData[index].title}</h2>
                <img src={videoTutorialData[index].video} alt="front-side" className='w-100' />
                <div className='mt-3 font-size-13'>
                    <b>Note: </b>No audio
                </div>
            </div>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default TutorialVideoModal;