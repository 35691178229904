import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import * as Yup from "yup";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { loginUserAction } from "../../../Redux/Action/AuthAction";
import SnackBarMessages from "../../UI/Snackbar/Snackbar";
import classes from "./login.module.css";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import GoogleLogin from "./GoogleLogin";
import { ResendEmailVerifyCode } from "../../../Redux/Services/AuthService";
import InputPassword from "../../UI/Input/InputPassword";
import { getAppsDetails,getBinaryDataOfClinicImage} from '../../../Redux/Services/AppsService';

class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      error: undefined,
      status: "",
      isLoading: false,
      isSnackbar: false,
      snackBarMessage: "",
      showPassword: false,
     
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("authToken") ){
      getAppsDetails(window.location.href.split('/login')[0])
      .then((resData) => {
          if (resData.data.data !== 'No Data') {
              localStorage.setItem('appDetails', JSON.stringify(resData.data.data));   

              getBinaryDataOfClinicImage(resData.data.data.app_logo_file).then((res) => {
                if (res.status == 200) {
                  const byteArray = new Uint8Array(res.data.data.binaryData.data); // Convert the array of integers to a byte array
                  const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Assuming it's a JPEG image
                  const imageUrl = URL.createObjectURL(blob);
                  localStorage.setItem('imageblob', JSON.stringify(res.data.data.binaryData.data));   
                 

                } 
              }).catch((err) => {
                console.log(err)
              })
                      }   
      }, (err) => {
          this.props.history.push('/');
      });
    }
    console.log(window.location.href.split('/login')[0],"wwwwww")
    if (localStorage.getItem("authToken") && localStorage.getItem("user")) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.loginResponse.response !== this.props.loginResponse.response
    ) {
      const loginResponse = this.props.loginResponse.response;
      if (loginResponse.isError) {
        this.loginFailureHandler(loginResponse);
      } else {
        this.loginSuccessHandler(loginResponse);
      }
    }
  }

  loginSuccessHandler(loginResponse) {
    localStorage.setItem("authToken", loginResponse.headers.authorization);
    localStorage.setItem("user", JSON.stringify(loginResponse.data.data));
    this.setState({
      status: this.props.loginResponse.response.status,
      isLoading: false,
      isSnackbar: true,
    });
    if (
      loginResponse.status === 200 &&
      this.props.location.state !== undefined
    ) {
      this.props.history.push("/camera-module-two");
    } else if (loginResponse.status === 200) {
      this.props.history.push("/dashboard");
    }
  }



  loginFailureHandler(loginResponse) {
    if (loginResponse?.data?.data.hasOwnProperty('is_email_verify')) {
      this.setState({ isLoading: false });
      ResendEmailVerifyCode({ email: loginResponse.data.data.email }).then((res) => {
        this.props.history.push("/verify-user-code", {
          email: loginResponse.data.data.email
        });
      }).catch((err) => {
        return this.setState({ error: "Incorrect Email. Please try again", isLoading: false, isSnackbar: true });
      });
    } else {
      this.setState({ error: this.props.loginResponse.message, isLoading: false, isSnackbar: true });
    }
  }

  handleClickShowPassword() {
    return this.setState({ ...this.state, showPassword: !this.state.showPassword });
  }

  render() {
    const handleSignupNavigation = () => {
      this.props.history.push("/signup", {
        comeBack: this.props.location.state !== undefined ? true : undefined,
      });
    };

    return (
      <Container className={`signUp-bg`}>
        {this.state.error && (
          <SnackBarMessages
            open={true}
            position="top-right"
            timing={5000}
            type="error"
            message={this.state.error}
          />
        )}
        <Row>
          <Col className="my-5">
            <h4 className={`${classes.LoginText} text-center mt-5 pt-4`}>
              Login
            </h4>
            <Row>
              <Col className="input">
                <Formik
                  validate={(values) => {
                    let errors = {};
                    if (!values.email) {
                      errors.email = "Email is Required";
                    } else if (!EmailValidator.validate(values.email)) {
                      errors.email = "Invalid email address.";
                    }

                    const passwordRegex = /(?=.*[0-9])/;
                    if (!values.password) {
                      errors.password = "Password is Required";
                    } else if (values.password.length < 8) {
                      errors.password = "Password must be 8 characters long.";
                    }
                    // else if (!passwordRegex.test(values.password)) {
                    //   errors.password =
                    //     "Invalid password. Must contain one number.";
                    // }
                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email().required("Required"),
                    password: Yup.string()
                      .required("No password provided.")
                      .min(
                        8,
                        "Password is too short - should be 8 chars minimum."
                      )
                    // .matches(
                    //   /(?=.*[0-9])/,
                    //   "Password must contain a number."
                    // ),
                  })}
                  initialValues={{ email: "", password: "" }}
                  onSubmit={(values, { setSubmitting }) => {
                    this.props.dispatch(loginUserAction(values));
                    setSubmitting(false);
                    this.setState({ isLoading: true, error: '' });
                  }}
                >
                  {(formProp) => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    } = formProp;

                    return (
                      <>
                        <form onSubmit={handleSubmit}>
                          <div className="input-with-icon-placeholder position-relative">
                            <input
                              id="email"
                              name="email"
                              type="text"
                              placeholder="Email"
                              className={`form-input my-3 ${classes["fontAwesome"]}`}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <i className="fa fa-envelope"></i>
                            {errors.email && touched.email && (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          </div>
                          <div className="input-with-icon-placeholder position-relative">
                            <InputPassword
                              id="password"
                              name="password"
                              placeholder="Password"
                              error={errors.password}
                              value={values.password}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched.password}
                            />
                          </div>
                          <Link to="/forgot-password">
                            <h4
                              className={`${classes["forgetText"]} d-inline-block my-4`}
                            >
                              Forgot Password ?
                            </h4>
                          </Link>

                          {this.state.error ? (
                            <div className="text-danger m-2">
                              {this.state.error}
                            </div>
                          ) : (
                            <div className="text-success m-2">
                              {this.state.error}
                            </div>
                          )}
                          <div className="text-center">
                            {this.state.isLoading ? (
                              <CircularProgress />
                            ) : (
                              <Button
                                type="submit"
                                // disabled={isSubmitting}
                                className="form-btn"
                              >
                                LOGIN
                              </Button>
                            )}
                          </div>
                        </form>
                        <div className="mt-5 text-center">
                          <GoogleLogin />
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h4 className={`${classes["login-createOne"]} my-3`}>
                  <span className="font-weight-bold">New here ? </span>
                  <button
                    className="login-link-text font-weight-bold"
                    onClick={handleSignupNavigation}
                  >
                    Sign up
                  </button>
                </h4>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginResponse: state.login,
  };
};

export default connect(mapStateToProps, null)(Login);
