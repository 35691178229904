import React from 'react';
import { Card } from 'react-bootstrap';

const GradesCard = ({ isTartar, isCavity }) => {
    return (
        <Card className="p-3 shadow my-3 mx-3">
            {
                isTartar && (
                    <>
                        <p>What do the grades mean?</p>
                        <p><b className="text-success">A</b> - Upto 6 surfaces with tartar</p>
                        <p><b className="text-success">B</b> - 6-12 surfaces with tartar</p>
                        <p><b className="text-warning">C</b> - 12-18 surfaces with tartar</p>
                        <p><b className="text-danger">D</b> - 18-24 surfaces with tartar</p>
                        <p><b className="text-danger">E</b> - More than 24 surfaces with tartar</p>
                    </>
                )
            }
            {
                isCavity && (
                    <>
                        <p>What do the grades mean?</p>
                        <p><b className="text-success">A</b> - Zero cavities</p>
                        <p><b className="text-danger">D</b> - One cavity</p>
                        <p><b className="text-danger">E</b> - More than one cavity</p>
                    </>
                )
            }
        </Card>
    )
}

export default GradesCard;