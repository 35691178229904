import React, { Component } from "react";
import Drawer from "./Drawer/Drawer";
import MainPage from "./MainPage/MainPage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "./../../assets/logo/logo67x67.svg";
import "./dashboard.css";
import {   getBinaryDataOfClinicImage,getAlignerAppVersion} from '../../Redux/Services/AppsService';
import ReloadApp from "../UI/Reload/ReloadApp";
class Dashboard extends Component {
  state = {
    cavity: 35,
    Tartar: 10,
    title: "Dashboard",
    loading:true,
    photo:false,
    imgs:[],
    reload: false,
    newVersion: ""
  };
  componentDidMount() {
    const appDetails= JSON.parse(localStorage.getItem('appDetails'));
    const imageblob = JSON.parse(localStorage.getItem("imageblob"))
    const user = localStorage.getItem("user")
    if (user) this.updateAppVersion()
    if(imageblob){
      const byteArray = new Uint8Array(imageblob); // Convert the array of integers to a byte array
        const blob = new Blob([byteArray], { type: "image/jpeg" }); // Assuming it's a JPEG image
      const imageUrl = URL.createObjectURL(blob);
      this.setState({imgs:[imageUrl]})
      this.setState({photo:true})
  }
    if (!imageblob & appDetails ) {
      
       getBinaryDataOfClinicImage(appDetails.app_logo_file)
      .then((res) => {
        if (res.status == 200) {
          const byteArray = new Uint8Array(
            res.data.data.binaryData.data
          ); // Convert the array of integers to a byte array
          const blob = new Blob([byteArray], { type: "image/jpeg" }); // Assuming it's a JPEG image
          const imageUrl = URL.createObjectURL(blob);
          localStorage.setItem('imageblob', JSON.stringify(res.data.data.binaryData.data));   
          this.setState({photo:true})
          this.setState({imgs:[imageUrl]})
        }
      })
      .catch((err) => {
        this.setState({ photo: false });
      });
                }
             
            }
  
  swapSlider(event) {
    if (event >= 1) {
      this.setState({ title: "Instruction for use" });
    } else {
      this.setState({ title: "Dashboard" });
    }
  }
  updateAppVersion() {

    const version = localStorage.getItem("version")
    getAlignerAppVersion().then((res) => {
      const newVersion = res.data.data.version
      const isUpdated = version === newVersion 
      if (!version || !isUpdated) {
        this.setState({newVersion, reload: true})
      }

  }).catch((err) => {
    console.log(err)
  })
}

  render() {
    const settings = {
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="dashboard-bg dashboard-overflow h-100">
        <Drawer title={this.state.title} />
        <div className="position-absolute right-0 p-2 width-40px z-index-9">
        {this.state.photo?<img src={this.state.imgs[0]} width="100" alt="logo" style={{maxHeight:"60px"}}/>: <img className="w-100" src={Logo} alt="logo" />
}     
        </div>
        <MainPage />
        <ReloadApp reload={this.state.reload} version={this.state.newVersion}/>

        {/* <Slider
          {...settings}
          className="dashboard-slider"
          afterChange={this.swapSlider.bind(this)}
        >
          <>
            <MainPage />
          </>
          <>
            <CameraModule />
          </>
        </Slider> */}
      </div>
    );
  }
}
export default Dashboard;
