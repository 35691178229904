import React from 'react';
import cssClasses from "./Splash.module.css";
import bgm from "./../../assets/logo/cameraModule1.png";
import tele from "./../../assets/logo/logo.jpeg";

const SplashPage = () => {
  return (
    <div className={`container ${cssClasses["qr-container"]}`}>
      <div className="p-5">
        <div className={`col-12 d-flex justify-content-center  my-5 `}>
          <div className={`${cssClasses["main"]}`}>
            <div className={`${cssClasses["sub"]}`}>
              <img src={bgm} alt="QR code img" />
            </div>
          </div>
        </div>
        <div className={`col-12 d-flex justify-content-center  my-5 `}>
          <div className={`${cssClasses["main1"]}`}></div>
        </div>
        <div className={`col-12 d-flex justify-content-center  my-5 `}>
          <div className={`${cssClasses["main"]}`}>
            <div className={`${cssClasses["sub"]}`}>
              <img src={tele} alt="QR code img" />
            </div>
          </div>
        </div>
        <div className={`col-12 d-flex justify-content-center text-white  my-5 `}>
          Powered by Toothlens
        </div>
      </div>
    </div>
  )
}

export default SplashPage