import * as types from './index';

export const registerUserAction = (user) => {
  return {
    type: types.REGISTER_USER,
    user
  }
};

export const loginUserAction = (user) => {
  return {
    type: types.LOGIN_USER,
    user
  }
};
export const forgotPwdAction = (userEmail) => {
  return {
    type: types.FORGOT_PASSWORD,
    userEmail
  }
};
export const verifyCodeAction = (verifyCode) => {
  return {
    type: types.VERIFY_CODE,
    verifyCode
   }
};

export const verifyUserCodeAction = (verifyCode) => {
  return {
    type: types.VERIFY_USER_CODE,
    verifyCode
   }
};

export const createNewPasswordAction = (createPassword) => {
  return {
    type: types.CREATE_NEW_PASSWORD,
    createPassword
   }
};

export const  profileAction = (profile) => {
  return {
    type: types.USER_PROFILE,
    profile
   }
};

// export const changePasswordAction = (changePassword) => {
//   console.log('changePassword', changePassword);
//   return {
//     type: types.CHANGE_PASSWORD,
//     changePassword
//    }
// };

// export const reportUploadAction = (report) => {
//   return {
//     type: types.USER_REPORT,
//     report 
//   }
// };