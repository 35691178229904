import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";
import DialogMessage from "../Dialog/Dialog";
import ReviewFlagExplainer from "../Card/ExplainerCard/ReviewFlagExplainer";
import { REPORT_IMG_URL } from "../../../Redux/Services/BaseUrl";

const SliderCavityTartarCard = (props) => {

  const [isDialog, setIsDialog] = useState(false);
  const [image, setImage] = useState('');
  const isShowReviewFlageExplainer = JSON.parse(localStorage.getItem('isShowReviewFlageExplainer')) === null ? true : false;
  const { is_quick_scan } = props.reportData;

  const reviewHandler = (obj) => {
    const splitImg = obj.split('-').join('.');
    setImage(splitImg);
    setIsDialog(true);
  }

  const onCloseDialog = () => {
    setIsDialog(false);
  }

  const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // centerMode: true,
    cssEase: "linear",
  };

  return (
    <>
      <Slider {...settings}>
        {props.reportScoreImg.map((item, index) => {
          return (
            <div key={item} className="cavity-slider">
              <Card className="cavity-card">
                <Card.Img
                  variant="top"
                  src={
                    !is_quick_scan ?
                    props?.reportImageScore[item][props.scoreIndex] > 0 ?
                      props.imgUrl + item.split("-").join(".") :
                      REPORT_IMG_URL + item.split("-").join(".") :
                      REPORT_IMG_URL + item.split("-").join(".")
                  }
                  alt="report images"
                />
              </Card>

              

            </div>
          );
        })}
      </Slider>
  
    </>

  );
};

export default SliderCavityTartarCard;
