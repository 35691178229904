export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const USER_REPORT = 'USER_REPORT';
export const USER_REPORT_SUCCESS = 'USER_REPORT_SUCCESS';
export const USER_REPORT_RESET = 'USER_REPORT_RESET';
export const USER_REPORT_ERROR = 'USER_REPORT_ERROR';
export const UPDATE_IS_RESHOOT = 'UPDATE_IS_RESHOOT';

export const USER_REPORT_UPLOAD = 'USER_REPORT_UPLOAD';
export const USER_REPORT_UPLOAD_SUCCESS = 'USER_REPORT_UPLOAD_SUCCESS';
export const USER_REPORT_UPLOAD_RESET = 'USER_REPORT_UPLOAD_RESET';
export const USER_REPORT_UPLOAD_ERROR = 'USER_REPORT_UPLOAD_ERROR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const VERIFY_CODE = 'VERIFY_CODE';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_ERROR = 'VERIFY_CODE_ERROR';

export const VERIFY_USER_CODE = 'VERIFY_USER_CODE';
export const VERIFY_USER_CODE_SUCCESS = 'VERIFY_USER_CODE_SUCCESS';
export const VERIFY_USER_CODE_ERROR = 'VERIFY_USER_CODE_ERROR';

export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const CREATE_NEW_PASSWORD_SUCCESS = 'CREATE_NEW_PASSWORD_SUCCESS';
export const CREATE_NEW_PASSWORD_ERROR = 'CREATE_NEW_PASSWORD_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CLEAN_CHANGE_PASSWORD = 'CLEAN_CHANGE_PASSWORD';

export const USER_PROFILE = 'USER_PROFILE';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export const USER_PROFILE_CLEAR = 'USER_PROFILE_CLEAR';