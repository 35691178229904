import { Dialog } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import Logo from "./../../../assets/logo/logo67x67.svg";

const PwaDialog = (props) => {
  const [isDialogOpen, setDialogOPen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState("");
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) 
  const imageblob = JSON.parse(localStorage.getItem("imageblob"))
  const appName = appDetails ? appDetails.app_name : "Toothlens"
  const appUrl = appDetails ? appDetails.app_url : "www.toothlens.com"
  var appLogo = Logo
  if (imageblob) {
    const byteArray = new Uint8Array(imageblob); // Convert the array of integers to a byte array
    const blob = new Blob([byteArray], { type: "image/jpeg" }); // Assuming it's a JPEG image
    appLogo = URL.createObjectURL(blob);
  }

  useEffect(() => {
    initialPwa();
    const pwa = JSON.parse(localStorage.getItem("pwaInstall"));
    setTimeout(() => {
      if (!pwa && deferredPrompt) {
        setDialogOPen(true);
      }
    }, 5000);
  }, [deferredPrompt]);

  const handleClose = () => {
    setDialogOPen((prevState) => !prevState);
  };

  const initialPwa = () => {
    // Initialize deferredPrompt for use later to show browser install prompt.
    // let deferredPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      // deferredPrompt = e;
      setDeferredPrompt(e);
      localStorage.removeItem("pwaInstall");
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    });
  };

  function getPWADisplayMode() {
    const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
    if (document.referrer.startsWith("android-app://")) {
      return "twa";
    } else if (navigator.standalone || isStandalone) {
      return "standalone";
    }
    return "browser";
  }

  const pwaPrompt = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(function (choiceResult) {
        console.log(choiceResult.outcome);

        if (choiceResult.outcome === "dismissed") {
          console.log("User cancelled installation");
        } else {
          console.log("User added to home screen");
          props.handlePwaPopup(true);
        }
        setDialogOPen(false);
      });
      setDeferredPrompt("");
    }
  };

  window.addEventListener("appinstalled", () => {
    // Clear the deferredPrompt so it can be garbage collected
    // deferredPrompt = null;
    setDeferredPrompt(null);
    localStorage.setItem("pwaInstall", JSON.stringify(true));
    // Optionally, send analytics event to indicate successful install
    console.log("PWA was installed");
  });

  return (
    <Fragment>
      <Dialog onClose={handleClose} open={isDialogOpen}>
        <Col>
          <Row className="p-3">
            <Col xs={12} className="mb-2">
              <h5>Install app</h5>
            </Col>
            <Col xs={2}>
               <img src={imageblob ? appLogo : Logo} alt="Logo" width="40" style={{maxWidth:"50px", maxHeight: "60px"}} />
            </Col>
            <Col xs={10}>
              {appName}
              <br />
              <small>{appUrl}</small>
            </Col>
            <Col className="text-right mt-2">
              <Button
                className="text-center font-size-16 primary-color mr-3"
                variant="text"
                size="sm"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                id="pwaPopup"
                className="text-center font-size-16 primary-color"
                variant="text"
                size="sm"
                onClick={pwaPrompt}
              >
                Install
              </Button>
            </Col>
          </Row>
        </Col>
      </Dialog>
    </Fragment>
  );
};

export default PwaDialog;
