import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


import Step1 from '../../../../assets/Instruction_GIF_s/Step-1.gif';
import Step2 from '../../../../assets/Instruction_GIF_s/Step-2.gif';
import Step3 from '../../../../assets/Instruction_GIF_s/Step-3.gif';
import Step4 from '../../../../assets/Instruction_GIF_s/Step-4.gif';
import Step5 from '../../../../assets/Instruction_GIF_s/Step-5.gif';
import Step6 from '../../../../assets/Instruction_GIF_s/Step-6.gif';
import Step7 from '../../../../assets/Instruction_GIF_s/Step-7.gif';
import Step8 from '../../../../assets/Instruction_GIF_s/Step-8.gif';
import Header from "../../../UI/Header/Header";
import IntroVideoModal from "../../../UI/Modal/IntroVideoModal";
const InstructionForUse = (props) => {

  const [isLastIndex, setIsLastIndex] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const reportCount = JSON.parse(localStorage.getItem('reportCount'));
const [video,setVideo]=useState(true)

  const tutorialImages = [
    {
      img: Step1,
      bottomText: 'Stand in front of mirror'
    },
    {
      img: Step2,
      bottomText: 'Turn on back camera for better clarity'
    },
    {
      img: Step3,
      bottomText: 'Turn on flash light for more brightness (for Android phones only)'
    },
    {
      img: Step4,
      bottomText: 'Now, take the picture inside of the mouth'
    },
    {
      img: Step5,
      bottomText: 'Torch on the rear side of the phone provides better lighting'
    },
    {
      img: Step6,
      bottomText: 'Rear camera generally takes higher resolution pictures'
    },
    {
      img: Step7,
      bottomText: 'Mirror makes it easier to see the entire phone screen'
    },
    {
      img: Step8,
      bottomText: 'Only the mouth needs to be inside the photo area and not the complete/other part of face'
    }
  ];

  const handleSelect = (selectedIndex) => {
    setSelectedIndex(selectedIndex);
    if (selectedIndex === tutorialImages.length - 1) {
      setIsLastIndex(true);
    } else {
      setIsLastIndex(false);
    }
  };

  const slideToNext = () => {
    const setIndex = selectedIndex + 1;
    if (setIndex <= 6) {
      return setSelectedIndex(setIndex);
    }
    setSelectedIndex(setIndex);
    setIsLastIndex(true);
  }


  const OnCloseDialog=()=> {
setVideo(false);
}

  return (
    <Container>
      <Header title="General Instructions" routeRedirection="/dashboard" />
      <Row className="margin-top-70px pt-3">
        <Col xs={12}>

       {video && <IntroVideoModal open={video}onClose={OnCloseDialog}/> }   
          {/* <Row>
            <div className="col-12 h-100 tutorial-carousel">
              <Carousel activeIndex={selectedIndex} onSelect={handleSelect} interval={null}>
                {tutorialImages.map((data, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <div className="col-12 p-0 rounded-left">
                        <div className="slider-image-size text-center">
                          <img
                            aria-label="image"
                            src={data.img}
                            className="w-100 mb-2 border-radius-25px"
                            alt="tutorial-img"
                          />
                          <span className="font-size-14 grey-color">{index + 1} of 8</span>
                        </div>
                      </div>
                      <Carousel.Caption className="pt-0 px-3">
                        <p className="black-color my-3">{data.bottomText}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </Row> */}
          {
            // isLastIndex ? (
            //   <div className="skip-tutorial text-center font-weight-600 mt-4 font-size-18">
            //     <Link to="/camera-module-two">
            //       <p>I am ready</p>
            //     </Link>
            //   </div>
            // ) : (
              // reportCount > 0 ? (
                <div className="skip-tutorial text-center font-weight-600 mt-4 font-size-18">
                  {/* <Link to="/camera-module-two">
                    <p>Skip Tutorial</p>
                  </Link> */}
                </div>
              // ) : (
              //   <div className="skip-tutorial text-center font-weight-600 mt-4 font-size-18 primary-color"
              //     onClick={slideToNext}>Swipe next</div>
              // )
            // )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default connect(null, null)(InstructionForUse);