import axios from "axios";
import { API_HOST } from "./BaseUrl";

export const ReviewReportPostAPI = (requestData) => {
  let authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin

  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `review-report`, requestData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const BlurCheckApi = (formData) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  // };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `report/blur-check`, formData)
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const updateCameraPermissionStatus = (requestData) => {
  let authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin

  };
  return new Promise((resolve, reject) => {
    return axios
      .put(API_HOST + `user/camera-permission`, requestData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const storeSentToDentist = (requestData) => {
  let authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin

  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `branch/send-to-dentist`, requestData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const reportPdfDownload = (requestData) => {
  let authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
    hosts:window.location.origin
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `report-pdf-download`,{ pdf_url:requestData}, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};
