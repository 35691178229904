import React, { Component } from "react";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import * as Yup from "yup";

import { connect } from "react-redux";
import { verifyUserCodeAction } from "../../../Redux/Action/AuthAction";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Swal from "sweetalert2";
import SnackBarMessages from "../../UI/Snackbar/Snackbar";

class VerifyUserCode extends Component {
  constructor() {
    super();
    this.state = {
      error: undefined,
      email: "",
      data: undefined,
      isLoading: false,
      message: 'Thank you for your information. Please check your email and enter the code below',
      isSnackbar: true
    };
  }
  componentDidMount() {
    const userEmail = this.props.location.state.email;
    if (userEmail) {
      this.setState({ email: userEmail });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.verification_code !== this.props.verification_code) {
      if (this.props.verification_code.message && !this.props.verification_code?.response) {
        this.setState({
          error: this.props.verification_code.message,
          isLoading: false,
          message: this.props.verification_code.message
         });
      }
      if (this.props.verification_code?.response?.data) {
        Swal.fire('Your code is validated. Please log in', '', 'success');
        this.setState({ data: this.props.verification_code.response.data, isLoading: false });
        this.props.history.push("/login", {
          data: this.state.data,
        });
      }
    }
  }

  render() {
    return (
      <div className="container h-100">

        {(this.state.error || this.state.isSnackbar) && (
          <SnackBarMessages
            open={true}
            type={this.state.error ? 'error' : 'success'}
            position="top-right"
            timing={10000}
            message={this.state.message}
          />
        )}

        <div className="row h-100 align-items-center justify-content-center mb-3">
          <div className="col">
            <h4 className="text-center">
              Signup Code
            </h4>
            <Formik
              validate={(values) => {
                let errors = {};

                if (!values.verification_code) {
                  errors.verification_code = "Code is Required";
                }
                return errors;
              }}
              validationSchema={Yup.object().shape({
                verification_code: Yup.string().required(
                  "Code is Required"
                ),
              })}
              initialValues={{ verification_code: "", email: "" }}
              onSubmit={(values, { setSubmitting }) => {
                values.email = this.state.email;
                this.props.dispatch(verifyUserCodeAction(values));
                setSubmitting(false);
                this.setState({ isLoading: true, error: false, isSnackbar: false });
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <input
                      id="verification_code"
                      name="verification_code"
                      type="text"
                      placeholder="Code"
                      className="form-input px-3 my-3 fontAwesome"
                      value={values.verification_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.verification_code && touched.verification_code && (
                      <div className="text-danger">
                        {errors.verification_code}
                      </div>
                    )}

                    {this.state.email ? (
                      <div className="text-danger m-2">{this.state.error}</div>
                    ) : (
                      <div className="text-success m-2">{this.state.error}</div>
                    )}

                    <div className="text-center">
                      {this.state.isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          type="submit"
                          className="form-btn"
                        >
                          Submit Code
                        </Button>
                      )}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    verification_code: state.verifyUserCode,
  };
};

export default connect(mapStateToProps, null)(VerifyUserCode);
