import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
// import 'react-html5-camera-photo/build/css/index.css';

import { connect } from "react-redux";
import MessageModal from "./../../UI/Modal/message-modal";
import StepsModal from "./../../UI/Modal/steps-modal";
import LoginModal from "./../../UI/Modal/login-modal";
import ScanningTooth from "../../UI/animation/ScanningTooth";

import ToothPhotosSubmitModal from "./../../UI/Modal/ToothPhotosSubmitModal";
import { CameraStepsData } from "./../../Utils/camera-static-data/CameraStepsData";
import CameraModuleButton from "../../UI/CameraModule/CameraModuleButton";
import CameraModuleBottomImage from "../../UI/CameraModule/CameraModuleBottomImage";
import CameraModuleBackground from "../../UI/CameraModule/CameraModuleBackground";
import CameraCancelButton from "../../UI/CameraModule/CameraCancelButton";
import "./CameraModule.css";
import { Button } from "@material-ui/core";
import QuickScanModal from "../../UI/Modal/QuickScanModal";
import QuickScanDemoImages from "../../UI/Modal/QuickScanDemoImages";
import QuickScanTutorialDialog from "../../UI/Dialog/QuickScanTutorialDialog";
import FailedTeeth from "./../../../assets/cameraModule/Failed.svg";

const CameraModuleTwo = (props) => {
  let history = useHistory();
  const [images, setImages] = useState(props.reportState.responseImages);
  const [showLogin, setShowLogin] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  const [quickScanPhotosCount, setQuickScanPhotoCount] = useState([]);
  const [isSubmitAndShare, setIsSubmitAndShare] = useState(false);
  const fullscreen = true;
  const upperBlock = CameraStepsData;
  const isQuickScan = props.location.state?.isQuickScan;
  const [isShowMessagePopup, setIsShowMessagePopup] = useState(true);
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(true);

  useEffect(() => {
    setImages(props.reportState.responseImages);

    const myImagesLength = props.myImages.length;
    const responseImages = props.reportState.responseImages[myImagesLength - 1];
    if (responseImages?.image_name) {
      setIsPhotoUploaded(true);
    } else {
      if (myImagesLength) {
        setIsPhotoUploaded(false);
      }else {
        setIsPhotoUploaded(true);
      }
    }
  }, [props.myImages, props.reportState]);

  const handleTakePhotoAnimationDone = (dataUri) => {
    // console.log("dataUri", dataUri);
  };

  const handleReportSubmit = (isShareWithDentalOffice = false) => {
    if (authToken == null && (props.myImages.length >= 5 || isQuickScan)) {
      setShowLogin(true);
    } else {
      if (props.myImages.length >= 5 || isQuickScan) {
        localStorage.setItem("report", JSON.stringify(images));
        isShareWithDentalOffice
          ? setIsSubmitAndShare(true)
          : setIsSubmitAndShare(false);
        setLoader(true);
        props.reportUpload({ images, isQuickScan, isShareWithDentalOffice });
      }
    }
  };

  const handleSignupNavigation = () => {
    history.push("/signup", { comeBack: true });
  };
  const handleLoginNavigation = () => {
    history.push("/login", { comeBack: true });
  };

  const redirectToReshoot = (img, index, imgContents) => {
    props.removeImage({ imgCount: index })
    props.updateImageValidity({ imgCount: index })

    history.push("/camera-module-first", {
      demoImage: upperBlock[index].image,
      imgCount: index + 1,
      data: upperBlock[index],
      isReshoot: true,
      overlay: `Overlay${index + 1}`,
      isQuickScan,
    });
  };
  const closeMessageDialog = () => {
    setIsShowMessagePopup(false);
  };

  const reshootImage = () => {
    redirectToReshoot(
      props.myImages[props.myImages.length - 1],
      props.myImages.length - 1
    );
  };

  return (
    <Container fluid className="bg-container camera-preview p-0">
      <Camera
        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
        isFullscreen={fullscreen}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        isImageMirror={false}
        className="WebCam"
      ></Camera>
      <CameraModuleBackground />
      <Row className="align-items-start header-photo-count h-100 overflow-auto mx-0 w-100">
        <Col xs={12} className="text-right pt-2">
          <CameraCancelButton routeUrl="/dashboard" />
        </Col>
        {/* <Col
          xs={12}
          className="d-flex justify-content-around mx-auto photo-count px-0"
        >
          
          {upperBlock.map((block, index) => {
            return props.myImages[index] === undefined ? (
              <CameraModuleButton item={block} isText={true} key={index} />
            ) : (
              <CameraModuleButton item={block} isText={false} key={index} />
            );
          })}
        </Col> */}
        <Col xs={12} className="px-0 text-center">
          {!isQuickScan ? (
            <>
              <Link to="/instruction-for-use">
                <Button className="text-white font-weight-bold instructions-btn tertiary-bg my-2">
                  <i className="far fa-images mr-2 font-weight-bold font-size-16"></i>
                  Back to Tutorial
                </Button>
              </Link>
              {props.myImages.length <= 4 && !props?.reportState?.isReshoot && props.reportUploadState.message !== "Something went wrong please try again!" && (
                <StepsModal upperBlock={upperBlock} images={images} myImages={props.myImages} isPhotoUploaded={isPhotoUploaded} />
              )}

              {props?.reportState?.isReshoot && (
                <div className="bg-white mx-3">
                  <Row className="flex-nowrap bg-white mx-3">
                    <Col xs={12} className="text-center">
                      <img src={FailedTeeth} alt="FailedTeeth" />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-auto flex-nowrap pt-3 bg-white">
                    <Col xs={11} className="black-color text-center mb-1 ">
                      {props?.reportState?.mouthDetectionMessage === "mouth far away" ?
                        <div className="text-center d-flex justify-content-center align-items-center" style={{ height: "50px" }}>
                          <p className="mouth-detection-message">The mouth was detected too far away from the camera. Please try bringing it closer.  </p>
                        </div> : <div className="text-center ">
                          <p> <b> Improper photo. This may be due to </b> </p>
                          <div className="d-flex flex-column align-items-start px-2 py-1">
                            <p> 1. Poor lighting </p>
                            <p> 2. Blurred image </p>
                            <p> 3. Image does not match the demo pic. </p>
                          </div>
                        </div>}
                    </Col>
                  </Row>
                  <button
                    onClick={reshootImage}
                    className=" mt-3 mb-2 btn btn-primary"
                  >
                    <span className="">Click here to reshoot</span>
                  </button>
                </div>
              )}
            </>
          ) : props.myImages.length > 0 ? (
            !showLoader && (
              <QuickScanModal
                imgCount={props.myImages.length + 1}
                isQuickScan={isQuickScan}
                onSubmit={handleReportSubmit}
              />
            )
          ) : (
            <>
              {isShowMessagePopup && (
                <QuickScanTutorialDialog
                  open={isShowMessagePopup}
                  message="Take the photo as close to the mouth as possible"
                  onClose={closeMessageDialog}
                />
              )}
              {!isShowMessagePopup && (
                <div>
                  <QuickScanDemoImages stepsData={CameraStepsData} />
                  <Link
                    to={{
                      pathname: "/camera-module-first",
                      state: {
                        data: "",
                        imgCount: props.myImages.length + 1,
                        isQuickScan: true,
                      },
                    }}
                  >
                    <Button
                      className="text-center font-size-19 text-uppercase text-white primary-bg font-weight-bold"
                      variant="contained"
                      size="small"
                    >
                      Take Photos
                    </Button>
                  </Link>
                </div>
              )}
            </>
          )}

          {!authToken && showLogin ? (
            <LoginModal
              signup={handleSignupNavigation}
              login={handleLoginNavigation}
            />
          ) : undefined}
          {props.reportUploadState.message !== undefined &&
            props.reportUploadState.message !== "" && (
              <MessageModal
                isQuestionnaireGiven={
                  props.reportUploadState?.response?.isQuestionnaireGiven
                }
                reportId={props.reportUploadState?.response?.report._id}
                isQuickScan={isQuickScan}
                isSubmitAndShare={isSubmitAndShare}
                message="Thanks for submitting your photos."
                isSuccess={
                  props.reportUploadState.message ===
                  "Something went wrong please try again!"
                    ? false
                    : true
                }
              />
            )}
          {showLoader && !props.reportUploadState.message ? (
            <ScanningTooth />
          ) : (
            !showLogin &&
            !props.reportUploadState.message &&
            props.myImages.length === 5 && !props?.reportState?.isReshoot && (
              <ToothPhotosSubmitModal isPhotoUploaded={isPhotoUploaded} onSubmit={handleReportSubmit} />
            )
          )}
        </Col>
        <Col xs={12} className="px-0">
          <Row className="align-items-end mx-0">
            <Col
              lg={8}
              xs={12}
              className="px-0 d-flex justify-content-around mx-auto"
            >
              <>
                {upperBlock.map((block, index) => {
                  return images[index]?.image_name === undefined ? (
                    <CameraModuleBottomImage
                      isImage={false}
                      key={index}
                      count={index + 1}
                      image={props.myImages[index]}
                      isReshoot={
                        props.reportState.responseImages[index].isReshootImage
                      }
                    />
                  ) : (
                    <CameraModuleBottomImage
                      isImage={true}
                      key={index}
                      image={props.myImages[index]}
                      isReshoot={
                        props.reportState.responseImages[index].isReshootImage
                      }
                    // onClick={() =>
                    //   redirectToReshoot(props.myImages[index], index)
                    // }
                    />
                  );
                })}
              </>
            </Col>
          </Row>
          <Row className="align-items-end mt-4 pr-3 mx-0">
            <Col xs={12} className="d-flex justify-content-end mx-auto">
              <div
                className={
                  !props.reportState.responseImages[4].image_name
                    ? "d-none"
                    : ""
                }
              ></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapDespatchToProps = (dispatch) => {
  return {
    removeImage: (imageData) => {
      dispatch({ type: "REMOVE_ONE_IMAGE", payload: imageData })
    },
    updateImageValidity: (imageData) => {
      dispatch({ type: "UPDATE_IS_RESHOOT", payload: imageData })
    },
    reportUpload: (reportUpload) => {
      dispatch({ type: "USER_REPORT_UPLOAD", reportUpload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    myImages: state.image,
    reportState: state.report,
    reportUploadState: state.reportUpload,
  };
};

export default connect(mapStateToProps, mapDespatchToProps)(CameraModuleTwo);
