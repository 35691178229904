import React from 'react';
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import BackArrow from "../../../assets/Dashboard/Drawer/back-arrow.svg";
import cssClasses from './Header.module.css';

const Header = (props) => {
    return (
        <Row>
        <Col
          xs={12}
          className={`d-flex align-items-center justify-content-center mb-3
          ${cssClasses['header-nav-container']} position-fixed`}
        >
          <Link to={props.routeRedirection} className={`position-absolute ${cssClasses['back-btn-icon']}`}>
            {/* <i className="fas fa-chevron-left"></i> */}
            <img src={BackArrow} alt="back-arrow-img" />
          </Link>
          <h1 className={`${cssClasses['header-title']} text-white mb-0 py-3 font-size-20 text-uppercase`}>
            {props.title}
          </h1>
        </Col>
      </Row>
    )
}

export default Header;