import * as types from "../Action";

const initState = {
  message: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  const response = action.payload;

  switch (action.type) {
    case types.CREATE_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        response,
        message: "Your password is successfully created..!!",
      };
    case types.CREATE_NEW_PASSWORD_ERROR:
      return {
        ...state,
        message: "Choose Diffrent Password!!!",
      };

    default:
      return state;
  }
}
