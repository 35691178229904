import { Button } from '@material-ui/core';
import ContentCopyIcon from '@material-ui/icons/FileCopyTwoTone';
import { Mail } from '@material-ui/icons';
import { Settings } from '@material-ui/icons';
import SafariImg from './../../../assets/icon/safari-icon.png';

const copyURL = () => {
    navigator.clipboard.writeText(window.location.href);
}

export const getGSAError = () => {
    return <p className="black-color font-size-16 my-2">
        Looks like you are using a iOS version less than 15.6.1 <br />
        This may result in camera functions not working if you clicked on an email link <Mail className='font-size-19' /> <br />
        Please update your iOS version or copy <br />
        <Button className='p-0 m-0 min-width-auto'><ContentCopyIcon onClick={copyURL} className='font-size-19' /></Button> <span className='primary-color'>{window.location.host} </span>
        and paste in native Safari browser <img src={SafariImg} width="20" alt="SafariImg" /> on your phone and you should have no issues</p>
}

export const getIOSError = () => {
    return (
        <p>Looks like you disabled camera permission. Swipe the screen down and accept camera permission to continue.
            You may also go to settings and enable camera permission</p>
    )
}

export const getAndroidError = () => {
    return <p>Looks like you have disabled camera permission. You will need to enable camera permission like in the video</p>
}

export const getOnePlusError = () => {
    return <p>Looks like you are using a device with earlier version of Android or Chrome .Your camera permissions maybe disabled
        <br/>To enable access <br/>
        <b>Settings {'>'}' Site settings {'>'} Camera {'>'} Enable camera</b>
    </p>
}