import React, { useEffect } from 'react';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

import './Rating.css';

export default function RadioGroupRating(props) {

  useEffect(() => {
    selectedRating(props.value);
  }, []);

  const selectedRating = (value) => {
    const span = document.querySelectorAll('.rating-span');
    props.selectedValue(value);
    for (let i = 0; i < 5; i++) {
      span[i].classList.remove('active-rating');
    }
    for (let i = 0; i < value; i++) {
      span[i].classList.add('active-rating');
    }
  }

  return (
    <div className="text-center grey-color">
      <span className="rating-span" onClick={() => selectedRating(1)}
      ><SentimentVeryDissatisfiedIcon /></span>
      <span className="rating-span" onClick={() => selectedRating(2)}
      ><SentimentDissatisfiedIcon /></span>
      <span className="rating-span" onClick={() => selectedRating(3)}
      ><SentimentSatisfiedIcon /></span>
      <span className="rating-span" onClick={() => selectedRating(4)}
      ><SentimentSatisfiedAltIcon /></span>
      <span className="rating-span" onClick={() => selectedRating(5)}
      ><SentimentVerySatisfiedIcon /></span>
    </div>
  );
}