import React from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import CameraDisabled from './../../../assets/video/camera-videos/camera-permission-enable.mp4';
import { getAndroidError, getGSAError, getIOSError, getOnePlusError } from './cameraErrorHelper';

const CAMERA_ERROR_POPUP_MESSAGE = {
    gsaError: getGSAError(),
    iosError: getIOSError(),
    androidError: getAndroidError(),
    onePlusError: getOnePlusError()
}

const CameraErrorPopup = (props) => {
    const { onClose, open, errorMessage } = props;

    const handleDialogAction = () => {
        if (errorMessage === 'gsaError') {
            localStorage.setItem('isShowGSAPopup', false);
        }
        onClose();
        
    }

    return (
        <Dialog open={open} className="rating-container">
            <div className='text-right px-3 mt-1'>
                <i className='fas fa-times camera-cancel-btn font-size-25 text-dark border-0'
                onClick={handleDialogAction}></i>
            </div>
            <div className="px-4">
                <h2 className="text-center font-size-21 primary-color font-weight-bold">INFO</h2>
                {
                    CAMERA_ERROR_POPUP_MESSAGE[errorMessage]
                }
                {
                    errorMessage === 'androidError' && (
                        <div className="w-100 px-3 mt-3">
                            <video className="w-100" controls autoPlay>
                                <source src={CameraDisabled} type="video/mp4" />
                            </video>
                        </div>
                    )
                }
                <p className='mt-2'>Have issues still? <a href='mailto:support@toothlens.com'>support@toothlens.com</a></p>
            </div>
            <DialogActions>
                <Button onClick={handleDialogAction} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default CameraErrorPopup;