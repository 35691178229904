import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import CameraAccessVideo from './../../../assets/video/camera-videos/cameraAccess.mp4';


function CameraAccessDialog(props) {
  const messageBox = useRef();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog open={open} className="rating-container p-2">
      <div className='text-right px-3 mt-1'>
                <i className='fas fa-times camera-cancel-btn font-size-25 text-dark border-0'
                onClick={handleClose}></i>
            </div>
      <div className="px-3 mb-3">
        <p>Looks like you are using a device with earlier version of Android or Chrome .Your camera permissions maybe disabled
          <br /><b>To enable access </b> <br />
          <b>Settings {'>'} Site settings {'>'} Camera {'>'} Enable camera</b>
        </p>
        <p className='mt-2'>Have issues still? <a href='mailto:support@toothlens.com'>support@toothlens.com</a></p>
      </div>
      {/* <div className="w-100 px-3">
        <video className="w-100" controls autoPlay>
          <source src={CameraAccessVideo} type="video/mp4" />
        </video>
      </div> */}

    </Dialog>
  );
}

export default CameraAccessDialog;
